import { Box, Button, Grid } from "@mui/material";
import api from "../../app/interceptor";
import Typography from "@mui/material/Typography";

const LoadBills = () => {
  const loadBillsHandler = () => {
    try {
      api.post("/external/loadBills", { billType: "FCCA" });
    } catch (error) {}
  };

  return (
    <Grid container direction="column" justifyContent="center">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "2rem",
          backgroundColor: "",
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          sx={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Cargar Facturas a ISC
        </Typography>
        <Button
          variant="contained"
          sx={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
          onClick={loadBillsHandler}
        >
          Realizar Carga
        </Button>
      </Box>
    </Grid>
  );
};

export default LoadBills;
