import RowOptions from "../quotation-report/rowOptions";

const getFields = (setRowId: any, setAction: any) => {
  return [
    {
      header: "Acciones",
      Cell: ({ row }: any) => (
        <>
          <RowOptions
            row_id={row.original.quotationid}
            setRowId={setRowId}
            setAction={setAction}
          />
        </>
      ),
      Size: 80,
    },
    {
      header: "Proforma",
      id: "quotationid",
      accessorKey: "quotationid",
    },
    {
      header: "Fecha De Emisión",
      id: "certificationdate",
      accessorKey: "certificationdate",
      Cell: ({ row }: any) =>
        new Date(row.original.certificationdate).toLocaleDateString(),
    },
    {
      header: "Orden de Produccion",
      id: "productionorder",
      accessorKey: "productionorder",
    },
    {
      header: "NIT",
      id: "nit",
      accessorKey: "nit",
    },
    {
      header: "Cliente",
      id: "customer",
      accessorKey: "customer",
    },
    {
      header: "Total",
      id: "grandtotal",
      accessorKey: "grandtotal",
      Cell: ({ row }: any) => (+parseFloat(row.original.grandtotal)).toFixed(2),
    },
    {
      header: "Envio",
      id: "shippings",
      accessorKey: "shippings",
      Cell: ({ row }: any) =>
        row.original.shippings === null ? "-" : row.original.shippings,
    },
    {
      header: "Tipo de Factura",
      id: "billtype",
      accessorKey: "billtype",
    },
    {
      header: "Estado",
      id: "status",
      accessorKey: "status",
      Cell: ({ row }: any) => (row.original.status ? "Activa" : "Anulada"),
    },
    {
      header: "Numero de Autorizacion",
      id: "authorizationnumber",
      accessorKey: "authorizationnumber",
    },
    {
      header: "Numero de Serie",
      id: "documentseries",
      accessorKey: "documentseries",
    },
    {
      header: "Numero de Documento",
      id: "documentnumber",
      accessorKey: "documentnumber",
    },
    {
      header: "Vendedor",
      id: "fullname",
      accessorKey: "fullname",
    },
    {
      header: "Correo Electronico",
      id: "email",
      accessorKey: "email",
    },
    {
      header: "Dias de Credito",
      id: "creditdays",
      accessorKey: "creditdays",
    },
    {
      header: "Tipo de Cambio",
      id: "exchangerate",
      accessorKey: "exchangerate",
    },
  ];
};

export default getFields;
