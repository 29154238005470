import styles from "./SideNavigationBar.module.css";

import { SideNavigation, SideNavigationItem } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/json-imports/Icons.js";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

export function SideNavigationBar() {
  const [, , removeCookie] = useCookies(["access"]);
  const [userCookie, , removeUserCookie] = useCookies(["user"]);
  const history = useHistory();

  return (
    <SideNavigation
      className={styles.sideNavigationBar}
      onSelectionChange={function noRefCheck() {}}
      slot=""
      style={{}}
      tooltip=""
    >
      {/* <SideNavigationItem
				icon="business-objects-experience"
				text="General"
				onClick={() => history.push("/adminDashboard")}
			/> */}
      <SideNavigationItem
        icon="create"
        text="Documentos"
        onClick={() => history.push("/documents")}
      />
      <SideNavigationItem
        icon="expense-report"
        text="Reportes"
        onClick={() => history.push("/reports")}
      />
      <SideNavigationItem
        icon="product"
        text="Productos"
        onClick={() => history.push("/product")}
      />
      <SideNavigationItem
        icon="customer-view"
        text="Clientes"
        onClick={() => history.push("/customer")}
      />
      {userCookie && userCookie.user.toString() === "1" && (
        <SideNavigationItem
          icon="employee"
          text="Usuarios"
          onClick={() => history.push("/user")}
        />
      )}

      {userCookie && userCookie.user.toString() === "1" && (
        <SideNavigationItem
          icon="synchronize"
          text="Sincronizar Facturas en ISC"
          onClick={() => {
            history.push("/loadBills");
          }}
        />
      )}
      <SideNavigationItem
        icon="system-exit"
        text="Salir"
        onClick={() => {
          removeCookie("access");
          removeUserCookie("user");
          history.push("/");
        }}
      />
    </SideNavigation>
  );
}
