import React, { useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import MaterialReactTable from "material-react-table";

interface Props {
  products: any[];
  setMustShow: (value: boolean) => void;
  setProduct: (newProducts: any[]) => void;
}

const DuplicateProducts: React.FC<Props> = ({
  products,
  setMustShow,
  setProduct,
}) => {
  const data = useMemo(() => products, [products]);
  const columns = useMemo(
    () => [
      {
        header: "Acciones",
        Cell: ({ row }: any) => (
          <Button
            size="small"
            variant="contained"
            onClick={() => setProduct([row.original])}
          >
            Ver
          </Button>
        ),
      },
      {
        header: "Id de Producto",
        id: "productid",
        accessorKey: "productid",
      },
      {
        header: "Precio Unitario (Q)",
        id: "unitprice",
        accessorKey: "unitprice",
        Cell: ({ row }: any): any => `Q${row.original.unitprice}`,
      },
      {
        header: "Descripcion",
        id: "quickoverview",
        accessorKey: "quickoverview",
      },
    ],
    []
  );

  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Button variant="contained" onClick={() => setMustShow(false)}>
        Atras
      </Button>
      <Box
        sx={{ display: "flex", flexDirection: "column", marginRight: "1rem" }}
      >
        <h1 style={{ marginLeft: "auto", marginRight: "auto" }}>
          Productos Similares
        </h1>
        <MaterialReactTable
          columns={columns}
          data={data}
          positionPagination="top"
          enableRowVirtualization
          muiTableContainerProps={{ sx: { maxHeight: "" } }}
          rowNumberMode="original"
          muiTableBodyRowProps={{ hover: true }}
        />
      </Box>
    </Box>
  );
};

export default DuplicateProducts;
