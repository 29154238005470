import styles from "./Documents.module.css";

import {
	Panel,
	TabContainer,
	Tab,
	ComboBoxItem,
	Loader,
} from "@ui5/webcomponents-react";

import { Quotation } from "../quotation/Quotation";
import { Shipping } from "../shipping/Shipping";
// import { Invoice } from "../invoice/Invoice";
// import { Notes } from "../notes/Notes";
import { Annulment } from "../annulment/Annulment";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { useCookies } from "react-cookie";

export function Documents() {
	/**
	 * Documents global props
	 */
	const [quotationStates, setQuotationStates] = useState<any[]>([]);
	const [currencies, setCurrencies] = useState<any[]>([]);
	const [quotationTypes, setQuotationTypes] = useState<any[]>([]);
	const [sellers, setSellers] = useState<any[]>([]);
	const [productTypes, setProductTypes] = useState<any[]>([]);
	const [billingCodes, setBillingCodes] = useState<any[]>([]);
	const [incoterms, setIncoterms] = useState<any[]>([]);
	const [deliveryMethods, setDeliveryMethods] = useState<any[]>([]);
	const [guarantee, setGuarantee] = useState<any[]>([]);
	const [creditDays, setCreditDays] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [cookie] = useCookies(["access"]);
	const [userCookie] = useCookies(["user"]);

	/**
	 * Load initial data to global props
	 */
	useEffect(() => {
		const source = axios.CancelToken.source();
		const apiAccess = {
			headers: { Authorization: `Bearer ${cookie["access"]}` },
		};

		const fetchMasterData = async () => {
			try {
				setLoading(true);
				const quotationStates = await axios.get(
					`${API_URL}/crud/quotationState`,
					{ cancelToken: source.token, ...apiAccess }
				);
				const serverQuotationStates = quotationStates.data.map(
					(quotationState: any) => (
						<ComboBoxItem
							key={quotationState.quotationstateid}
							text={quotationState.name}
						/>
					)
				);
				setQuotationStates(serverQuotationStates);
				const currencies = await axios.get(`${API_URL}/crud/currency`, {
					cancelToken: source.token,
					...apiAccess,
				});
				const serverCurrencies = currencies.data.map((currency: any) => (
					<ComboBoxItem key={currency.currencyid} text={currency.name} />
				));
				setCurrencies(serverCurrencies);
				const quotationTypes = await axios.get(
					`${API_URL}/crud/quotationType`,
					{ cancelToken: source.token, ...apiAccess }
				);
				const serverQuotationTypes = quotationTypes.data.map(
					(quotationType: any) => (
						<ComboBoxItem
							key={quotationType.quotationtypeid}
							text={quotationType.name}
						/>
					)
				);
				setQuotationTypes(serverQuotationTypes);
				const sellers = await axios.get(`${API_URL}/seller/active`, {
					cancelToken: source.token,
					...apiAccess,
				});
				const serverSellers = sellers.data.map((seller: any) => (
					<ComboBoxItem
						key={seller.sellerid}
						text={`${seller.sellercode} - ${seller.fullname}`}
					/>
				));
				setSellers(serverSellers);
				const productTypes = await axios.get(`${API_URL}/crud/productType`, {
					cancelToken: source.token,
					...apiAccess,
				});
				const serverProducTypes = productTypes.data.map((productType: any) => (
					<ComboBoxItem
						key={productType.producttypeid}
						text={productType.name}
					/>
				));
				setProductTypes(serverProducTypes);
				const billingCodes = await axios.get(`${API_URL}/crud/billingCode`, {
					cancelToken: source.token,
					...apiAccess,
				});
				const serverBillingCodes = billingCodes.data.map((billingCode: any) => (
					<ComboBoxItem
						key={billingCode.billingcodeid}
						text={billingCode.name}
					/>
				));
				setBillingCodes(serverBillingCodes);
				const incoterms = await axios.get(`${API_URL}/crud/incoterm`, {
					cancelToken: source.token,
					...apiAccess,
				});
				const serverIncoterms = incoterms.data.map((incoterm: any) => (
					<ComboBoxItem
						key={incoterm.incotermid}
						text={`${incoterm.name} - ${incoterm.description}`}
					/>
				));
				setIncoterms(serverIncoterms);
				const deliveryMethods = await axios.get(
					`${API_URL}/crud/deliveryMethod`,
					{ cancelToken: source.token, ...apiAccess }
				);
				const serverDeliveryMethdos = deliveryMethods.data.map(
					(deliveryMethod: any) => (
						<ComboBoxItem
							key={deliveryMethod.deliverymethodid}
							text={deliveryMethod.name}
						/>
					)
				);
				setDeliveryMethods(serverDeliveryMethdos);
				const warranty = await axios.get(`${API_URL}/crud/warranty`, {
					cancelToken: source.token,
					...apiAccess,
				});
				const serverGuarantee = warranty.data.map((warranty: any) => (
					<ComboBoxItem key={warranty.warrantyid} text={warranty.name} />
				));
				setGuarantee(serverGuarantee);
				const creditDays = await axios.get(`${API_URL}/crud/creditDays`, {
					cancelToken: source.token,
					...apiAccess,
				});
				setCreditDays(creditDays.data);
				setLoading(false);
			} catch (error) {}
		};

		fetchMasterData();

		return () => {
			source.cancel();
		};
	}, [cookie]);

	/**
	 * Wraps quotations props
	 * @returns Quotations props
	 */
	const getQuotationProps = () => {
		return {
			quotationStates: quotationStates,
			currencies: currencies,
			quotationTypes: quotationTypes,
			sellers: sellers,
			productTypes: productTypes,
			billingCodes: billingCodes,
			incoterms: incoterms,
			deliveryMethods: deliveryMethods,
			guarantee: guarantee,
			creditDays: creditDays,
		};
	};

	/**
	 * Wraps invoices props
	 * @returns Invoices props
	 */
	// const getInvoiceProps = () => {
	// 	return {
	// 		quotationStates: quotationStates,
	// 		currencies: currencies,
	// 		quotationTypes: quotationTypes,
	// 		sellers: sellers,
	// 		productTypes: productTypes,
	// 		billingCodes: billingCodes,
	// 	};
	// };

	const getShippingProps = () => {
		return {
			productTypes: productTypes,
			billingCodes: billingCodes,
		};
	};

	return (
		<div className={styles.documents}>
			<Panel headerText="Emisión de Documentos">
				{loading && <Loader />}
				<TabContainer onTabSelect={function noRefCheck() {}}>
					{!loading && (
						<>
							<Tab icon="sales-order" text="Proforma">
								<Quotation data={getQuotationProps()} />
							</Tab>
							<Tab icon="shipping-status" text="Envio">
								<Shipping data={getShippingProps()} />
							</Tab>
							{/* <Tab icon="sales-quote" text="Factura">
								<Invoice data={getInvoiceProps()} />
							</Tab>
							<Tab icon="sales-order-item" text="Notas">
								<Notes />
							</Tab> */}
							<Tab
								icon="delete"
								text="Anulacion"
								disabled={userCookie.user.toString() !== "1"}
							>
								<Annulment />
							</Tab>
						</>
					)}
				</TabContainer>
			</Panel>
		</div>
	);
}
