import styles from "./Quotation.module.css";
import {
  Panel,
  Table,
  TableColumn,
  Label,
  TableRow,
  TableCell,
  ResponsiveGridLayout,
  Input,
  Icon,
  ComboBox,
  DatePicker,
  Dialog,
  ComboBoxItem,
  Loader,
  Badge,
  Popover,
  Bar,
  Title,
  List,
  StandardListItem,
  MessageStrip,
  SuggestionItem,
} from "@ui5/webcomponents-react";

import { saveAs } from "file-saver";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { QuotationDTO, quotationInitialState } from "../../models/QuotationDTO";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { Product } from "../../models/product.model";
import { Modal, Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { TableComponent } from "../../components/table-body/TableComponent";
import { TableToolbar } from "../../components/table-toolbar/TableToolbar";
import { BillInfo, billInfoInitalValue } from "../../models/billInfo";
import { ClientInfo } from "../../components/client-info/ClientInfo";
import { CustomerForm } from "../customer/CustomerForm";
import { Customer, customerInitialValue } from "../../models/customer.model";
import { SnackBar } from "../../components/snack-bar/SnackBar";

import "@ui5/webcomponents/dist/Assets.js";
import "@ui5/webcomponents-fiori/dist/Assets.js";
import "@ui5/webcomponents-react/dist/Assets.js";

let productsView: any[] = [];
export function Quotation(props: any) {
  /**
   * Quotation global state and searching
   */
  const [quotation, setQuotation] = useState<QuotationDTO>(
    quotationInitialState
  );
  const [quotationSearch, setQuotationSearch] = useState<string>("");
  const [customers, setCustomers] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<string>("");
  const { quotationId } = useParams<any>();
  const [fetchingQuotation, setFetchingQuotation] = useState<boolean>(false);

  /**
   * Quotation properties
   */
  const [quotationStates, setQuotationStates] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [quotationTypes, setQuotationTypes] = useState<any[]>([]);
  const [sellers, setSellers] = useState<any[]>([]);
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [billingCodes, setBillingCodes] = useState<any[]>([]);
  const [incoterms, setIncoterms] = useState<any[]>([]);
  const [deliveryMethods, setDeliveryMethods] = useState<any[]>([]);
  const [guarantee, setGuarantee] = useState<any[]>([]);
  const [creditDays, setCreditDays] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<any>();

  const showMessage = useRef<Ui5DialogDomRef>(null);
  const popoverRef = useRef<any>();
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [createBillConfirmation, setCreateBillConfirmation] = useState(false);
  const [billDate, setBillDate] = useState(0);
  const [billing, setBilling] = useState(false);
  const [quotationsShippings, setQuotationsShippings] = useState<any[]>([]);
  const [cookie] = useCookies(["access"]);
  const [userCookie] = useCookies(["user"]);
  const [nicknameCookie] = useCookies(["nickname"]);
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie["access"]}` },
  };

  /**
   * Variables used to alerts messages
   */
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [notificationType, setNotificationType] = useState<any>(undefined);

  /**
   * Variables used to share data between components
   */
  const billinfo: BillInfo = billInfoInitalValue;
  let quotationTypeName = "";
  let quotationCurrencyName = "";

  /**
   * Quotations customer properties
   */
  const [customer, setCustomer] = useState<Customer>(customerInitialValue);

  /**
   * Quotations products properties
   */
  const [product, setProduct] = useState<any>({});
  const [products, setProducts] = useState<any[]>([]);
  const [record, setRecord] = useState<Record<string, any>>({});
  const [allProducts, setAllProducts] = useState<any[]>([]);

  /*
   * TodayExchange
   * */
  const [todayExchange, setTodayExchange] = useState("");

  const [cfFlag, setCfFlag] = useState<boolean>(false);
  const [showCreateClient, setShowCreateClient] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  // ER to production orders validation
  const OPRegex = new RegExp("^([0-9]{5,})(-[0-9]{5,})*$");
  const nombreConsignatarioRegex = new RegExp("^[a-zA-Z ]{1,70}$");
  const direccionConsignatarioRegex = new RegExp("^.{1,70}$");
  /*
   * get the todayExchange value
   * */
  useEffect(() => {
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie["access"]}` },
    };
    if (todayExchange === "") {
      if (cookie["access"] !== undefined) {
        axios
          .get(`${API_URL}/external/exchange`, apiAccess)
          .then((exchange: any) => {
            setTodayExchange(exchange.data.ratio);
          });
      }
    }

    return () => {
      setTodayExchange("");
    };

    // eslint-disable-next-line
  }, []);

  /**
   * Loads initial data to global props
   */
  useEffect(() => {
    if (props.data) {
      setQuotationStates(props.data.quotationStates);
      setCurrencies(props.data.currencies);
      setQuotationTypes(props.data.quotationTypes);
      setSellers(props.data.sellers);
      setProductTypes(props.data.productTypes);
      setBillingCodes(props.data.billingCodes);
      setIncoterms(props.data.incoterms);
      setDeliveryMethods(props.data.deliveryMethods);
      setGuarantee(props.data.guarantee);
      setCreditDays(props.data.creditDays);
    } else {
      const source = axios.CancelToken.source();
      const apiAccess = {
        headers: { Authorization: `Bearer ${cookie["access"]}` },
      };
      const fetchMasterData = async () => {
        try {
          const quotationStates = await axios.get(
            `${API_URL}/crud/quotationState`,
            { cancelToken: source.token, ...apiAccess }
          );
          const serverQuotationStates = quotationStates.data.map(
            (quotationState: any) => (
              <ComboBoxItem
                key={quotationState.quotationstateid}
                text={quotationState.name}
              />
            )
          );
          setQuotationStates(serverQuotationStates);
          const currencies = await axios.get(`${API_URL}/crud/currency`, {
            cancelToken: source.token,
            ...apiAccess,
          });
          const serverCurrencies = currencies.data.map((currency: any) => (
            <ComboBoxItem key={currency.currencyid} text={currency.name} />
          ));
          setCurrencies(serverCurrencies);
          const quotationTypes = await axios.get(
            `${API_URL}/crud/quotationType`,
            { cancelToken: source.token, ...apiAccess }
          );
          const serverQuotationTypes = quotationTypes.data.map(
            (quotationType: any) => (
              <ComboBoxItem
                key={quotationType.quotationtypeid}
                text={quotationType.name}
              />
            )
          );
          setQuotationTypes(serverQuotationTypes);
          const sellers = await axios.get(`${API_URL}/seller/active`, {
            cancelToken: source.token,
            ...apiAccess,
          });
          const serverSellers = sellers.data.map((seller: any) => (
            <ComboBoxItem
              key={seller.sellerid}
              text={`${seller.sellercode} - ${seller.fullname}`}
            />
          ));
          setSellers(serverSellers);
          const productTypes = await axios.get(`${API_URL}/crud/productType`, {
            cancelToken: source.token,
            ...apiAccess,
          });
          const serverProducTypes = productTypes.data.map(
            (productType: any) => (
              <ComboBoxItem
                key={productType.producttypeid}
                text={productType.name}
              />
            )
          );
          setProductTypes(serverProducTypes);
          const billingCodes = await axios.get(`${API_URL}/crud/billingCode`, {
            cancelToken: source.token,
            ...apiAccess,
          });
          const serverBillingCodes = billingCodes.data.map(
            (billingCode: any) => (
              <ComboBoxItem
                key={billingCode.billingcodeid}
                text={billingCode.name}
              />
            )
          );
          setBillingCodes(serverBillingCodes);
          const incoterms = await axios.get(`${API_URL}/crud/incoterm`, {
            cancelToken: source.token,
            ...apiAccess,
          });
          const serverIncoterms = incoterms.data.map((incoterm: any) => (
            <ComboBoxItem
              key={incoterm.incotermid}
              text={`${incoterm.name} - ${incoterm.description}`}
            />
          ));
          setIncoterms(serverIncoterms);
          const deliveryMethods = await axios.get(
            `${API_URL}/crud/deliveryMethod`,
            { cancelToken: source.token, ...apiAccess }
          );
          const serverDeliveryMethdos = deliveryMethods.data.map(
            (deliveryMethod: any) => (
              <ComboBoxItem
                key={deliveryMethod.deliverymethodid}
                text={deliveryMethod.name}
              />
            )
          );
          setDeliveryMethods(serverDeliveryMethdos);
          const warranty = await axios.get(`${API_URL}/crud/warranty`, {
            cancelToken: source.token,
            ...apiAccess,
          });
          const serverGuarantee = warranty.data.map((warranty: any) => (
            <ComboBoxItem key={warranty.warrantyid} text={warranty.name} />
          ));
          setGuarantee(serverGuarantee);
          const creditDays = await axios.get(`${API_URL}/crud/creditDays`, {
            cancelToken: source.token,
            ...apiAccess,
          });
          setCreditDays(creditDays.data);
          setQuotationSearch(quotationId);
          findQuotationByCode(quotationId);
        } catch (error) {}
      };
      fetchMasterData();
      return () => {
        source.cancel();
      };
    }
    // eslint-disable-next-line
  }, [props, cookie]);

  // useEffect(() => {
  //   if (notificationType) {
  //     setTimeout(() => setNotificationType(undefined), 10000);
  //   }
  // }, [notificationType]);

  /**
   * Handles inputs changes
   * @param event Input event
   * @param field Field to be affected
   */
  const handleInputChange = (event: any, field: string) => {
    setQuotation({ ...quotation, [field]: event.target.value });
  };

  /**
   * Handles comboboxes changes
   * @param event Combobox event
   * @param field Field to be affected
   */
  const handleComboBoxChange = (event: any, field: string) => {
    switch (field) {
      case "quotationStateId":
        for (let i = 0; i < quotationStates.length; i++) {
          if (quotationStates[i].props.text === event.target.value.toString()) {
            setQuotation({
              ...quotation,
              [field]: parseInt(quotationStates[i].key),
            });
            break;
          }
        }
        break;
      case "currencyId":
        for (let i = 0; i < currencies.length; i++) {
          if (currencies[i].props.text === event.target.value.toString()) {
            setQuotation({
              ...quotation,
              [field]: parseInt(currencies[i].key),
            });
            break;
          }
        }
        break;
      case "quotationTypeId":
        for (let i = 0; i < quotationTypes.length; i++) {
          if (quotationTypes[i].props.text === event.target.value.toString()) {
            setQuotation({
              ...quotation,
              [field]: parseInt(quotationTypes[i].key),
            });
            break;
          }
        }
        break;
      case "sellerId":
        for (let i = 0; i < sellers.length; i++) {
          if (sellers[i].props.text === event.target.value.toString()) {
            setQuotation({
              ...quotation,
              [field]: parseInt(sellers[i].key),
            });
            break;
          }
        }
        break;
      case "productTypeId":
        for (let i = 0; i < productTypes.length; i++) {
          if (productTypes[i].props.text === event.target.value.toString()) {
            setProduct({
              ...product,
              producttypeid: parseInt(productTypes[i].key),
            });
            break;
          }
        }
        break;
      case "billingCodeId":
        for (let i = 0; i < billingCodes.length; i++) {
          if (billingCodes[i].props.text === event.target.value.toString()) {
            setProduct({
              ...product,
              billingcodeid: parseInt(billingCodes[i].key),
            });
            break;
          }
        }
        break;
      case "incotermId":
        for (let i = 0; i < incoterms.length; i++) {
          if (incoterms[i].props.text === event.target.value.toString()) {
            setQuotation({
              ...quotation,
              [field]: parseInt(incoterms[i].key),
            });
            break;
          }
        }
        break;
      case "deliveryMethodId":
        for (let i = 0; i < deliveryMethods.length; i++) {
          if (deliveryMethods[i].props.text === event.target.value.toString()) {
            setQuotation({
              ...quotation,
              [field]: parseInt(deliveryMethods[i].key),
            });
            break;
          }
        }
        break;
      case "warrantyId":
        for (let i = 0; i < guarantee.length; i++) {
          if (guarantee[i].props.text === event.target.value.toString()) {
            setQuotation({
              ...quotation,
              [field]: parseInt(guarantee[i].key),
            });
            break;
          }
        }
        break;
    }
  };

  /**
   * Sets the current quotations state
   * @returns Current quotations state
   */
  const getSelectedQuotationState = (): string => {
    if (quotation.quotationStateId === 0) {
      return "";
    }
    for (let i = 0; i < quotationStates.length; i++) {
      if (quotationStates[i].key === quotation.quotationStateId.toString()) {
        return quotationStates[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current quotations currency
   * @returns Current quotations currency
   */
  const getSelectedCurrency = (): string => {
    if (quotation.currencyId === 0) {
      return "";
    }
    for (let i = 0; i < currencies.length; i++) {
      if (currencies[i].key === quotation.currencyId.toString()) {
        quotationCurrencyName = currencies[i].props.text;
        return currencies[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current quotations type
   * @returns Current quotations type
   */
  const getSelectedQuotationType = (): string => {
    if (quotation.quotationTypeId === 0) {
      return "";
    }
    for (let i = 0; i < quotationTypes.length; i++) {
      if (quotationTypes[i].key === quotation.quotationTypeId.toString()) {
        quotationTypeName = quotationTypes[i].props.text;
        return quotationTypes[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current quotations seller
   * @returns Current quotations seller
   */
  const getSelectedSeller = (): string => {
    if (quotation.sellerId === 0) {
      return "";
    }
    for (let i = 0; i < sellers.length; i++) {
      if (sellers[i].key === quotation.sellerId.toString()) {
        return sellers[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current quotations incoterm
   * @returns Current quotations incoterm
   */
  const getSelectedIncoterm = (): string => {
    if (quotation.incotermId === 0) {
      return "";
    }
    for (let i = 0; i < incoterms.length; i++) {
      if (incoterms[i].key === quotation.incotermId.toString()) {
        return incoterms[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current quotations delivery method
   * @returns Current quotations delivery method
   */
  const getSelectedDeliveryMethod = (): string => {
    if (quotation.deliveryMethodId === 0) {
      return "";
    }
    for (let i = 0; i < deliveryMethods.length; i++) {
      if (deliveryMethods[i].key === quotation.deliveryMethodId.toString()) {
        return deliveryMethods[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current quotations warranty
   * @returns Current quotations warranty
   */
  const getSelectedWarranty = (): string => {
    if (quotation.warrantyId === 0) {
      return "";
    }
    for (let i = 0; i < guarantee.length; i++) {
      if (guarantee[i].key === quotation.warrantyId.toString()) {
        return guarantee[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current prodcuts type
   * @returns Current products type
   */
  const getSelectedProductType = (): string => {
    if (!product.producttypeid) {
      return "";
    }
    for (let i = 0; i < productTypes.length; i++) {
      if (productTypes[i].key === product.producttypeid.toString()) {
        return productTypes[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current billing code
   * @returns Current billing code
   */
  const getSelectedBillingCode = (): string => {
    if (!product.billingcodeid) {
      return "";
    }
    for (let i = 0; i < billingCodes.length; i++) {
      if (billingCodes[i].key === product.billingcodeid.toString()) {
        return billingCodes[i].props.text;
      }
    }
    return "";
  };

  /**
   * Calculates product subtotal
   * @returns Product subtotal
   */
  const getProductSubtotal = (): string => {
    if (product.quantity && product.unitprice && product.discount) {
      const quantity = parseFloat(product.quantity);
      const unitprice = parseFloat(product.unitprice);
      const discount = parseFloat(product.discount);
      const result = (
        (unitprice - (unitprice * discount) / 100) *
        quantity
      ).toFixed(2);
      return result;
    }
    return "";
  };

  /**
   * Adds products to the quotation
   */
  const addProduct = () => {
    if (product && product.quantity && product.productid) {
      const quantity = parseFloat(product.quantity);
      const unitprice = parseFloat(product.unitprice);
      const discount = parseFloat(product.discount);
      const result = ((unitprice * (100 - discount)) / 100) * quantity;
      const newProduct = {
        ...product,
        subtotal: result.toFixed(2),
        registrationdate: "",
        unitprice: parseFloat(
          ((unitprice * (100 - discount)) / 100).toFixed(5)
        ),
      };
      setProducts([...products, newProduct]);
      setProduct({});
    }
  };

  const addProductToRecord = (newProduct: any) => {
    let prevState = { ...record };
    if (newProduct.registrationdate && newProduct.registrationdate !== "") {
      prevState[new Date(newProduct.registrationdate).toISOString()] =
        newProduct;
    } else {
      newProduct.registrationdate = new Date().toISOString();
      prevState[newProduct.registrationdate] = newProduct;
    }

    setRecord((prev) => ({ ...prev, ...prevState }));
  };

  const deleteProduct = (product: any) => {
    let temporalRecord = record;
    delete temporalRecord[new Date(product.registrationdate).toISOString()];
    setProducts(
      products.filter((currentProduct) => currentProduct !== product)
    );
  };

  useEffect(() => {
    for (const i in products) {
      addProductToRecord(products[i]);
    }
  }, [products]);

  /**
   * Handles vat update
   */
  useEffect(() => {
    if (products.length > 0) {
      let grandTotal = 0;
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        grandTotal += parseFloat(product.subtotal);
      }
      grandTotal = parseFloat(grandTotal.toFixed(4));
      if (quotation.grandTotal !== grandTotal) {
        const totalWithoutVat = grandTotal / 1.12;
        const totalWithVat = totalWithoutVat * 0.12;
        setQuotation({
          ...quotation,
          totalWithoutVat: parseFloat(totalWithoutVat.toFixed(4)),
          totalWithVat: parseFloat(totalWithVat.toFixed(4)),
          grandTotal: parseFloat(grandTotal.toFixed(4)),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, quotation]);

  /*
   * clients cant be CF with a total quotation > 2500
   * */
  useEffect(() => {
    let quotationValue = quotation.grandTotal;
    if (quotation.currencyId === 2) {
      quotationValue = quotation.grandTotal * +todayExchange;
    }

    if (quotationValue >= 2500 && customer.nit === "CF") {
      setCfFlag(true);
      setOpenSnackBar(true);
      setNotificationType("warning");
      setNotificationMessage(
        `No se puede facturar con un monto total mayor o igual a Q2500 con CF.
         Para continuar dirigete a la seccion de clientes y agrega uno o crea uno nuevo`
      );
    } else {
      setCfFlag(false);
    }
  }, [quotation, customer, todayExchange]);

  /**
   * Saves a new quotation
   * @param quotation Quotation
   * @returns Quotations data or error while create
   */
  const saveInternalQuotation = async (quotation: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/crud/quotation`,
        quotation,
        apiAccess
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Saves a new quotations product
   * @param product Quotations product
   * @returns Quotations product data or error while create
   */
  const saveInternalQuotationProduct = async (product: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/crud/quotationProduct`,
        product,
        apiAccess
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Updates a quotation
   * @param quotation Quotation
   * @returns Quotations data or error while update
   */
  const updateInternalQuotation = async (quotation: any) => {
    try {
      const response = await axios.put(
        `${API_URL}/crud/quotation`,
        quotation,
        apiAccess
      );

      setNotificationType("success");
      setNotificationMessage("Proforma modificada con exito");
      setOpenSnackBar(true);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Cleans quotation data
   * @param data Quotations init save data
   * @returns Quotations clean save data
   */
  const quotationSaveData = (data: any) => {
    const attributes = Object.keys(data);
    let finalData: any = {};
    for (let i = 0; i < attributes.length; i++) {
      finalData[attributes[i]] = data[attributes[i]];
    }
    delete finalData.search;
    delete finalData.quotationId;
    let creationDate = new Date();
    creationDate.setHours(creationDate.getHours() - 6);
    finalData.creationDate = creationDate.toISOString();
    finalData.nickname = nicknameCookie["nickname"];
    delete finalData.billingDate;
    if (finalData.quotationTypeId === 1) {
      delete finalData.incotermId;
    } else if (finalData.quotationTypeId === 2) {
      delete finalData.deliveryMethodId;
      delete finalData.warrantyId;
      delete finalData.dateOfDelivery;
    } else {
      delete finalData.incotermId;
      delete finalData.deliveryMethodId;
      delete finalData.warrantyId;
      delete finalData.dateOfDelivery;
    }
    return finalData;
  };

  /**
   * Cleans quotation data
   * @param data Quotations init update data
   * @returns Quotations cleans update data
   */
  const quotationUpdateData = (data: any) => {
    const attributes = Object.keys(data);
    let finalData: any = {};
    for (let i = 0; i < attributes.length; i++) {
      finalData[attributes[i]] = data[attributes[i]];
    }
    delete finalData.search;
    delete finalData.quotationId;
    delete finalData.creationDate;
    delete finalData.billingDate;
    delete finalData.incotermId;
    delete finalData.nickname;
    return {
      new: finalData,
      filter: {
        quotationId: data.quotationId,
      },
    };
  };

  /**
   * Handles quotation save
   * @param currentQuotationValue Current quotation value
   */
  const saveQuotationHandler = async (currentQuotationValue: any) => {
    const [saveQuotationData] = await saveInternalQuotation(
      quotationSaveData(currentQuotationValue)
    );
    if (!saveQuotationData) {
      displayMessage(
        "Error al crear proforma",
        "Verifica los datos e intenta nuevamente."
      );
      return;
    }
    const quotationId = saveQuotationData.data[0].quotationid;
    setQuotation({
      ...quotation,
      quotationId: quotationId,
      nickname: nicknameCookie["nickname"],
    });
    for (let i = 0; i < products.length; i++) {
      delete products[i].registrationdate;
      products[i].quotationId = quotationId;
      await saveInternalQuotationProduct(products[i]);
    }
    displayMessage("Proforma guardada", `Proforma #${quotationId} creada.`);
  };

  /**
   * Deletes quotations products
   * @param quotationId Quotation id
   * @returns Quotations products delete or an error
   */
  const deleteQuotationProductsRequest = async (quotationId: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/delete-quotation-products`,
        { quotationId: quotationId },
        apiAccess
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Handlers quotation update
   * @param currentQuotationValue Current quotation value
   */
  const updateQuotationHandler = async (currentQuotationValue: any) => {
    const [updateQuotationData] = await updateInternalQuotation(
      quotationUpdateData(currentQuotationValue)
    );
    if (!updateQuotationData) {
      displayMessage(
        "Error al actualizar proforma",
        "Verifica los datos e intenta nuevamente."
      );
      setNotificationType("error");
      setNotificationMessage(
        "Error al actualizar la proforma. Verifica los datos e intenta nuevamente"
      );
      return;
    }
    const [deleteProductsData] = await deleteQuotationProductsRequest(
      currentQuotationValue.quotationId.toString()
    );
    if (!deleteProductsData) {
      displayMessage(
        "Error al actualizar proforma",
        "Verifica los datos e intenta nuevamente."
      );
      setNotificationType("error");
      setNotificationMessage(
        "Error al actualizar la proforma. Verifica los datos e intenta nuevamente"
      );
      return;
    }
    for (let i = 0; i < products.length; i++) {
      delete products[i].registrationdate;
      delete products[i].quotationproductid;
      delete products[i].quotationId;
      products[i].quotationid = currentQuotationValue.quotationId.toString();
      await saveInternalQuotationProduct(products[i]);
    }
  };

  /**
   * Quotations save orchestrator
   */
  const saveQuotation = async () => {
    if (!(await verifyQuotationIntegrity())) return;
    const currentQuotationValue = quotation;
    if (currentQuotationValue.quotationId === 0) {
      saveQuotationHandler(currentQuotationValue);
    } else {
      if (quotationsShippings.length > 0) {
        displayMessage(
          "Error al modificar proforma",
          "No se puede modificar una proforma con envios."
        );
        return;
      }
      updateQuotationHandler(currentQuotationValue);
    }
  };

  /**
   * Restores quotations init state
   */
  const restoreQuotationState = () => {
    setProduct({});
    setProducts([]);
    setRecord({});
    setCustomer(customerInitialValue);
    setQuotation({ ...quotationInitialState });
    setQuotationSearch("");
    setQuotationsShippings([]);
  };

  /**
   * Gets shippings per quotation
   * @param quotationId Quotation id
   * @returns Quotations shippings data or an error
   */
  const quotationsShippingsRequests = async (quotationId: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/get-quotations-shippings`,
        { quotationId: quotationId },
        apiAccess
      );
      return [response.data.rows, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Finds quotation by code
   */
  const findQuotationByCode = async (quotationSearch: string) => {
    setFetchingQuotation(true);
    const response = await axios.post(
      `${API_URL}/query/find-quotation-by-id`,
      {
        quotationid: quotationSearch,
      },
      apiAccess
    );

    if (!response.status) {
      restoreQuotationState();
      displayMessage("Error al buscar proforma", "La proforma no existe.");
      return;
    }
    if (response.data.rows.length > 0) {
      restoreQuotationState();
      setQuotationSearch(response.data.rows[0].quotationid);
      cleanQuotationData(response.data.rows[0]);
      const [quotationProductsData, quotationProductsError] =
        await getQuotationProducts(quotationSearch);
      if (!quotationProductsError) {
        setProducts([]);
        setRecord({});
        for (let i = 0; i < quotationProductsData.rows.length; i++) {
          cleanQuotationProductData(quotationProductsData.rows[i]);
        }
      }
      const [quotationShippingsData] = await quotationsShippingsRequests(
        response.data.rows[0].quotationid
      );
      if (quotationShippingsData) {
        setQuotationsShippings(quotationShippingsData);
      }

      const [customerData, customerError] = await findCustomerByIdRequest(
        response.data.rows[0].customerid
      );

      if (customerError) {
        return;
      }

      setCustomer(customerData[0]);

      // find-customer-by-id
    } else {
      restoreQuotationState();
    }
    setFetchingQuotation(false);
  };

  /**
   * Maps quotation data
   * @param data Quotation mapped data
   */
  const cleanQuotationData = (data: any) => {
    setQuotation({
      quotationId: data.quotationid,
      creationDate: data.creationdate,
      billingDate: data.billingdate,
      dateOfDelivery: data.dateofdelivery,
      productionOrder: data.productionorder,
      purchaseOrder: data.purchaseorder,
      description: data.description,
      observations: data.observations,
      dteID: data.dteid,
      quotationStateId: data.quotationstateid,
      quotationTypeId: data.quotationtypeid,
      export: data.export === "true" ? true : false,
      currencyId: data.currencyid,
      sellerId: data.sellerid,
      customerId: data.customerid,
      totalWithVat: parseFloat(data.totalwithvat),
      totalDiscounts: parseFloat(data.totaldiscounts),
      totalWithoutVat: parseFloat(data.totalwithoutvat),
      grandTotal: parseFloat(data.grandtotal),
      deliveryAddress: data.deliveryaddress,
      deliveryCountry: data.deliverycountry,
      deliveryMethodId: data.deliverymethodid,
      incotermId: data.incotermid,
      warrantyId: data.warrantyid,
      warrantyDescription: data.warrantydescription,
      consigneeName: data.consigneename,
      consigneeAddress: data.consigneeaddress,
      consigneeCode: data.consigneecode,
      exporterName: data.exportername,
      exporterCode: data.exportercode,
      buyersName: data.buyersname,
      buyersAddress: data.buyersaddress,
      buyersCode: data.buyerscode,
      exportDescription: data.exportdescription,
      nickname: data.nickname,
    });
  };

  /**
   * Gets customers credit days data
   * @returns Customers credit days name
   */
  const getCustomerCreditDays = (): string => {
    if (creditDays.length === 0) {
      return "";
    }
    if (quotation.customerId !== 0) {
      return creditDays.filter(
        (creditDay: any) => creditDay.creditdaysid === customer.creditdaysid
      )[0].days;
    }
    return "";
  };

  /**
   * Maps quotation product data
   * @param data Quotation product data
   */
  const cleanQuotationProductData = (data: any) => {
    setProducts((prevState) => {
      return prevState.concat([data]);
    });
  };

  /**
   * Searches quotation related products
   * @returns Quotations product data
   */
  const getQuotationProducts = async (quotationSearch: string) => {
    try {
      const result = await axios.post(
        `${API_URL}/query/find-quotation-products-by-id`,
        { quotationid: quotationSearch },
        apiAccess
      );
      return [result.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Generates quotations bill
   */
  const createBill = async () => {
    if (
      (quotation.dteID !== "" && quotation.dteID !== null) ||
      (quotation.billingDate !== "" && quotation.billingDate !== null)
    ) {
      switch (quotation.quotationTypeId) {
        case 1:
          viewFile(`${API_URL}/external/exchangeBill/${quotation.quotationId}`);
          break;
        case 2:
          viewFile(`${API_URL}/external/exportBill/${quotation.quotationId}`);
          break;
      }
      return;
    }
    setCreateBillConfirmation(true);
  };

  /**
   * Generates quotations bill
   */
  const printBill = async () => {
    if (
      (quotation.dteID !== "" && quotation.dteID !== null) ||
      (quotation.billingDate !== "" && quotation.billingDate !== null)
    ) {
      switch (quotation.quotationTypeId) {
        case 1:
          try {
            await handlePrintBill(
              `${API_URL}/external/exchangeBillPrint/${quotation.quotationId}`
            );
          } catch (error) {
            setOpenSnackBar(true);
            setNotificationType("warning");
            setNotificationMessage(
              "Error al intentar obtener el pdf de la factura"
            );
          }

          break;
        case 2:
          viewFile(
            `${API_URL}/external/exportBillPrint/${quotation.quotationId}`
          );
          break;
      }
      return;
    }
    setCreateBillConfirmation(true);
  };

  /**
   * Handle download bill pdf
   * @param url the api endpoint url
   *
   */
  const handlePrintBill = async (url: string) => {
    const headers: any = apiAccess;
    headers["responseType"] = "blob";
    await axios
      .get(url, headers)
      .then((response) => {
        let disposition = response.headers["content-disposition"];
        const dispositonSplit = disposition.split("=");
        const name = dispositonSplit[1].replaceAll('"', "");
        saveAs(
          new Blob([response.data], { type: "application/pdf;charset=UTF-8" }),
          `${name}.pdf`
        );
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanBillingDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result
      .toLocaleString("es-GT", { timeZone: "America/Guatemala" })
      .split(" ")[0]
      .replaceAll(",", "");
  };

  /**
   * Displays quotation rendered data
   */
  const showQuotation = () => {
    if (quotation.quotationId !== 0) {
      viewFile(`${API_URL}/external/quotation/${quotation.quotationId}`);
    }
  };

  /**
   * Handles product combobox change
   * @param e event
   */
  const handleProductsComboBox = async (e: any) => {
    const name = e.target.value.toString();
    const code = name.split(" ")[0];
    for (let i = 0; i < allProducts.length; i++) {
      const product = allProducts[i];
      if (product.productid.toString() === code.toString()) {
        setProduct(product);
        return;
      }
    }
  };

  /**
   * Displays a dialog message
   * @param title Message title
   * @param content Message content
   */
  const displayMessage = (title: string, content: string) => {
    setMessageTitle(title);
    setMessageContent(content);
    showMessage.current?.show();
  };

  /**
   * Handles exchange bill creation
   */
  const handleExchangeBillCreation = async (date: Date) => {
    const extraDays = parseInt(
      creditDays.filter(
        (creditDay: any) => creditDay.creditdaysid === customer.creditdaysid
      )[0].days
    );
    date.setHours(date.getHours() - 6);
    let lastDate = new Date();
    lastDate.setDate(date.getDate() + extraDays);
    await axios
      .post(
        `${API_URL}/external/exchangeBill`,
        {
          date: date.toISOString().replace("Z", ""),
          quotation: quotation,
          customer: customer,
          products: Object.values(record),
          lastDate: lastDate.toISOString().replace("Z", ""),
        },
        apiAccess
      )
      .then((response: any) => {
        if (!!response.data.error) {
          setNotificationType("error");
          setNotificationMessage(
            "Error con el certificador al procesar la solicitud. Consulte con IT"
          );
          return;
        }

        const billData = response.data.data;
        setQuotation({
          ...quotation,
          billingDate: billData[0].certificationdate,
          dteID: `${billData[0].documentseries}-${billData[0].documentnumber}`,
        });
        popoverRef.current.close();
        setErrorMessage(null);
        viewFile(`${API_URL}/external/exchangeBill/${quotation.quotationId}`);
      })
      .catch((error) => {
        setQuotation({ ...quotation, billingDate: "" });
        setOpenSnackBar(true);
        setNotificationType("error");
        setNotificationMessage(
          `${error.response.data.error[0].fuente}. Comunicate con el soporte IT.`
        );

        // setErrorMessage(error.response.data);
      });
  };

  /**
   * Handles export bill creation
   */
  const handleExportBillCreation = async (date: Date) => {
    const extraDays = parseInt(
      creditDays.filter(
        (creditDay: any) => creditDay.creditdaysid === customer.creditdaysid
      )[0].days
    );
    date.setHours(date.getHours() - 6);
    let lastDate = new Date();
    lastDate.setDate(date.getDate() + extraDays);
    await axios
      .post(
        `${API_URL}/external/exportBill`,
        {
          date: date.toISOString().replace("Z", ""),
          quotation: quotation,
          customer: customer,
          products: Object.values(record),
          lastDate: lastDate.toISOString().replace("Z", ""),
        },
        apiAccess
      )
      .then((response) => {
        const billData = response.data.data;
        setQuotation({
          ...quotation,
          billingDate: billData[0].certificationdate,
          dteID: `${billData[0].documentseries}-${billData[0].documentnumber}`,
        });
        popoverRef.current.close();
        viewFile(`${API_URL}/external/exportBill/${quotation.quotationId}`);
      })
      .catch((error) => {
        setQuotation({ ...quotation, billingDate: "" });
        setOpenSnackBar(true);
        setNotificationType("error");
        setNotificationMessage(
          `${error.response.data.error[0].fuente}. Comunicate con el soporte IT.`
        );
      });
  };

  /**
   * handles bill confirmation
   */
  const handleCreateBillConfirmation = async () => {
    setCreateBillConfirmation(false);
    setBilling(true);
    let billingDate = new Date();
    let tempQuotation = quotation;
    tempQuotation.billingDate = cleanBillingDate(billingDate);
    setQuotation(tempQuotation);

    if (billDate === 2) {
      billingDate.setDate(billingDate.getDate() + 1);
      tempQuotation.billingDate = cleanBillingDate(billingDate);
      setQuotation(tempQuotation);
    }
    try {
      switch (quotation.quotationTypeId) {
        case 1:
          await handleExchangeBillCreation(billingDate);
          break;
        case 2:
          await handleExportBillCreation(billingDate);
          break;
      }
    } catch (error) {
      setNotificationType("error");
      setNotificationMessage("Error al facturar");
      //displayMessage("Error al crear factura", "Comunicate con el soporte IT.");
    }
    setBilling(false);
    setCreateBillConfirmation(false);
  };

  /**
   * Opens an file
   * @param url File url
   */
  const viewFile = async (url: any) => {
    fetch(url, apiAccess)
      .then((response) => response.blob())
      .then((blob) => {
        let _url: any = window.URL.createObjectURL(blob);
        window.open(_url, "_blank")?.focus();
      })
      .catch((err) => {
        setNotificationType("error");
        setNotificationMessage(err.toString());
      });
  };

  /**
   * Gets customer data by id
   * @param customerId Customers id
   * @returns Customer data or an error
   */
  const findCustomerByIdRequest = async (customerId: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/find-customer-by-id`,
        {
          customerId: customerId,
        },
        apiAccess
      );
      return [response.data.rows, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Finds customer by name
   */
  const findCustomerById = async () => {
    const customerFinalId = customers.find(
      (customer: any) => customer.fullname.toString() === customerId.toString()
    );
    if (!customerFinalId) {
      displayMessage("Error", "El cliente no se encuentra en el sistema");
      return;
    }

    const [customerData, customerErrorData] = await findCustomerByIdRequest(
      customerFinalId.customerid
    );
    if (customerErrorData) {
      displayMessage("Error", "El cliente no se encuentra en el sistema");
      return;
    }
    if (customerData.length > 0) {
      setCustomer(customerData[0]);
      setQuotation({
        ...quotation,
        customerId: customerData[0].customerid,
      });
    }
  };

  /**
   * Verifies quotation data integrity
   * @returns True if the quotations data is valid
   */
  const verifyQuotationIntegrity = async () => {
    if (quotation.sellerId === 0) {
      displayMessage("Error", "Selecciona al vendedor");
      return false;
    }
    if (quotation.quotationStateId === 0) {
      displayMessage("Error", "Selecciona el estado de la proforma");
      return false;
    }
    if (quotation.currencyId === 0) {
      displayMessage("Error", "Selecciona el tipo de moneda");
      return false;
    }
    if (quotation.quotationTypeId === 0) {
      displayMessage("Error", "Selecciona el tipo de proforma");
      return false;
    }
    if (quotation.productionOrder === "") {
      displayMessage("Error", "Ingresa la orden de producción");
      return false;
    }
    if (!OPRegex.test(quotation.productionOrder)) {
      displayMessage(
        "Error",
        "La orden de producción NO CUMPLE con el formato establecido"
      );
      return false;
    }
    if (quotation.purchaseOrder === "") {
      displayMessage("Error", "Ingresa la orden de compra");
      return false;
    }
    if (quotation.description === "") {
      displayMessage("Error", "Ingresa la descripción de la proforma");
      return false;
    }
    if (quotation.customerId === 0) {
      displayMessage("Error", "Selecciona al cliente");
      return false;
    }
    if (!products) {
      displayMessage("Error", "Ingresa productos a la proforma");
      return false;
    }
    if (products.length <= 0) {
      displayMessage("Error", "Ingresa productos a la proforma");
      return false;
    }
    switch (quotation.quotationTypeId) {
      case 1:
        if (quotation.dateOfDelivery === "") {
          displayMessage("Error", "Ingresa la fecha de entrega");
          return false;
        }
        if (quotation.deliveryAddress === "") {
          displayMessage("Error", "Ingresa la dirección de entrega");
          return false;
        }
        if (quotation.deliveryMethodId === 0) {
          displayMessage("Error", "Ingresa la forma de entrega");
          return false;
        }
        if (quotation.warrantyId === 0) {
          displayMessage("Error", "Ingresa la garantía");
          return false;
        }
        if (quotation.observations === "") {
          displayMessage("Error", "Ingresa las observaciones de entrega");
          return false;
        }
        break;
      case 2:
        if (quotation.buyersName === "") {
          displayMessage("Error", "Ingresa el nombre de envio");
          return false;
        }
        if (quotation.buyersAddress === "") {
          displayMessage("Error", "Ingrese la dirección del envio");
          return false;
        }
        if (quotation.incotermId === 0) {
          displayMessage("Error", "Seleccione el INCOTERM de la proforma");
          return false;
        }
        if (!nombreConsignatarioRegex.test(quotation.buyersName)) {
          displayMessage(
            "Error",
            "El nombre no puede contener números o caracteres especiales"
          );
          return false;
        }
        if (!direccionConsignatarioRegex.test(quotation.buyersAddress)) {
          displayMessage(
            "Error",
            "La dirección del consignatario solo puede contener 70 caracteres"
          );
          return false;
        }
        break;
    }
    return true;
  };

  const handleOpenBillingDatePicker = (e: any) => {
    if (!quotation.billingDate) {
      popoverRef.current.showAt(e.target);
    } else {
      createBill();
    }
  };

  const handleCloseBillingDatePicker = () => {
    popoverRef.current.close();
  };

  //handleInputChange
  const handleBillingDatePicker = (billDay: number) => {
    return (e: any) => {
      defineBillHeaderVisualisation(billDay);
      fillProductsView();
      setBillDate(billDay);
      createBill();
      popoverRef.current.close();
      e.preventDefault();
    };
  };

  const getFilteredProduct = (e: any) => {
    let queryString: string = e.target.value.toString();
    queryString =
      queryString.slice(-1) === " " ? queryString.slice(0, -1) : queryString;
    queryString = queryString.replaceAll(" ", "&");

    if (e.target.value.toString().length > 0) {
      const apiAccess = {
        headers: { Authorization: `Bearer ${cookie["access"]}` },
      };
      axios
        .post(
          `${API_URL}/query/get-filtered-products`,
          { name: queryString },
          apiAccess
        )
        .then((response: any) => {
          if (response.data.rows.length > 0) {
            setAllProducts(response.data.rows);
          }
        })
        .catch((error) => {
          console.log(error);
          // setOpenSnackBar(true);
          // setNotificationType("warning");
          // setNotificationMessage(
          //   "Ha ocurrido un error al intentar obtener los productos"
          // );
        });
    }
  };

  const handleGetFilteredCustomers = (e: any) => {
    let queryString: string = e.target.value.toString();
    queryString =
      queryString.slice(-1) === " " ? queryString.slice(0, -1) : queryString;
    queryString = queryString.replaceAll(" ", "&");

    if (e.target.value.toString().length > 0) {
      const apiAccess = {
        headers: { Authorization: `Bearer ${cookie["access"]}` },
      };
      axios
        .post(
          `${API_URL}/query/get-customers`,
          { name: queryString },
          apiAccess
        )
        .then((response: any) => {
          if (response.data.rows.length > 0) {
            setCustomers(response.data.rows);
          }
        })
        .catch((error) => {
          setOpenSnackBar(true);
          setNotificationType("warning");
          setNotificationMessage(
            "Ha ocurrido un error al intentar obtener los clientes"
          );
        });
    }
  };

  const defineBillHeaderVisualisation = (billDay: number) => {
    billinfo.authorizationnumber = "####SIN CERTIFICAR####";
    billinfo.documentnumber = "#SIN CERTIFICAR#";
    billinfo.serienumber = "#SIN CERTIFICAR#";
    billinfo.customeremail = customer.email;
    billinfo.customernit = customer.nit;
    billinfo.emissiondate = new Date();
    billinfo.certificationdate =
      billDay === 1 ? new Date() : new Date(Date.now() + 3600 * 1000 * 24);
    billinfo.customername = customer.fullname;
    billinfo.billtype = quotationTypeName;
    billinfo.billstatus = "#SIN CERTIFICAR#";
    billinfo.currency = quotationCurrencyName;
  };

  const fillProductsView = () => {
    productsView = [];
    for (let i = 0; i < products.length; i++) {
      productsView.push({
        productId: products[i].productid,
        billCode: products[i].billingcodeid.toString(),
        description: products[i].quickoverview,
        //productType: products[i].producttypeid.toString(),
        quantity: products[i].quantity,
        unitPrice: products[i].unitprice,
        discount: products[i].discount,
        subtotal: products[i].subtotal,
      });
    }
  };

  const showCreateClientForm = (value: boolean) => {
    setShowCreateClient(value);
  };

  const handleCreateCustomer = (newCustomer: any) => {
    try {
      const apiAccess = {
        headers: { Authorization: `Bearer ${cookie["access"]}` },
      };
      axios
        .post(
          `${API_URL}/customer/quick-creation`,
          {
            customerData: newCustomer,
            quotationData: {
              currencyid: quotation.currencyId,
              quotationtypeid: quotation.quotationTypeId,
              sellerid: quotation.sellerId,
            },
          },
          apiAccess
        )
        .then((response) => {
          setCustomer({ ...customer, ...response.data.data });
          setQuotation({ ...quotation, customerId: customer.customerid });
          setCfFlag(false);
          setShowCreateClient(false);
        })
        .catch((error) => {
          setNotificationType("error");
          setNotificationMessage(error.response.data.message);
          setOpenSnackBar(true);
        });
    } catch (e) {
      setOpenSnackBar(true);
      setNotificationType("warning");
      setNotificationMessage("Error inesperado al crear el cliente");
    }
  };

  return (
    <div>
      <Panel className={styles.headerText} headerText="Datos Generales">
        <ResponsiveGridLayout
          columnsXL={4}
          columnsL={2}
          columnsM={1}
          columnsS={1}
        >
          <div>
            <Input
              icon={<Icon name="number-sign" />}
              onInput={(e) => setQuotationSearch(e.target.value.toString())}
              placeholder="Correlativo"
              className={styles.input}
              value={quotationSearch}
            />
          </div>
          <div>
            <Button
              onClick={() => findQuotationByCode(quotationSearch)}
              className={styles.button}
              endIcon={<SearchIcon />}
              variant="outlined"
              disabled={fetchingQuotation}
            >
              Buscar Proforma
            </Button>
          </div>

          <div>
            <ComboBox
              icon={<Icon name="employee" />}
              onChange={(e) => handleComboBoxChange(e, "sellerId")}
              placeholder="Vendedor"
              className={styles.input}
              value={getSelectedSeller()}
              style={{
                border: quotation.sellerId === 0 ? "2px solid red" : "",
              }}
            >
              {sellers}
            </ComboBox>
          </div>
          <div>
            <ComboBox
              icon={<Icon name="travel-request" />}
              onChange={(e) => handleComboBoxChange(e, "quotationStateId")}
              placeholder="Estado Proforma"
              className={styles.input}
              value={getSelectedQuotationState()}
              style={{
                border: quotation.quotationStateId === 0 ? "2px solid red" : "",
              }}
            >
              {quotationStates}
            </ComboBox>
          </div>
          <div>
            <Input
              icon={<Icon name="calendar" />}
              onInput={(e) => handleInputChange(e, "creationDate")}
              className={styles.input}
              value={cleanBillingDate(quotation.creationDate)}
              placeholder="Fecha Creación"
              readonly
            />
          </div>
          <div>
            <Input
              icon={<Icon name="calendar" />}
              onInput={(e) => handleInputChange(e, "billingDate")}
              className={styles.input}
              value={cleanBillingDate(quotation.billingDate)}
              placeholder="Fecha Facturación"
              readonly
            />
          </div>
          <div>
            <ComboBox
              icon={<Icon name="lead" />}
              onChange={(e) => handleComboBoxChange(e, "currencyId")}
              placeholder="Moneda"
              className={styles.input}
              value={getSelectedCurrency()}
              style={{
                border: quotation.currencyId === 0 ? "2px solid red" : "",
              }}
            >
              {currencies}
            </ComboBox>
          </div>
          <div>
            <ComboBox
              icon={<Icon name="globe" />}
              onChange={(e) => handleComboBoxChange(e, "quotationTypeId")}
              placeholder="Tipo de Proforma"
              className={styles.input}
              value={getSelectedQuotationType()}
              style={{
                border: quotation.quotationTypeId === 0 ? "2px solid red" : "",
              }}
              disabled={quotation.billingDate !== ""}
            >
              {quotationTypes}
            </ComboBox>
          </div>
          <div>
            <Input
              icon={<Icon name="factory" />}
              onInput={(e) => handleInputChange(e, "productionOrder")}
              placeholder="Orden de Producción"
              className={styles.input}
              value={quotation.productionOrder}
              style={{
                border: quotation.productionOrder === "" ? "2px solid red" : "",
              }}
            />
          </div>
          <div>
            <Input
              icon={<Icon name="customer-order-entry" />}
              onInput={(e) => handleInputChange(e, "purchaseOrder")}
              placeholder="Orden de Compra"
              className={styles.input}
              value={quotation.purchaseOrder}
              style={{
                border: quotation.purchaseOrder === "" ? "2px solid red" : "",
              }}
            />
          </div>
          <div>
            <Input
              icon={<Icon name="sales-order" />}
              onInput={(e) => handleInputChange(e, "dteID")}
              placeholder="ID DTE"
              className={styles.input}
              value={quotation.dteID}
              readonly
            />
          </div>
          <div>
            <Input
              icon={<Icon name="legend" />}
              onInput={(e) => handleInputChange(e, "description")}
              placeholder="Descripcion"
              className={styles.input}
              value={quotation.description}
              style={{
                border: quotation.description === "" ? "2px solid red" : "",
              }}
            />
          </div>
        </ResponsiveGridLayout>
      </Panel>

      {showCreateClient === false ? (
        <ClientInfo
          disabled={
            quotation.billingDate
              ? quotation.billingDate
                ? true
                : false
              : false
          }
          filteredCustomers={customers}
          customer={customer}
          setCustomerId={setCustomerId}
          handleGetFilteredCustomers={handleGetFilteredCustomers}
          findCustomerById={findCustomerById}
          getCustomerCreditDays={getCustomerCreditDays}
          showAddButton={cfFlag}
          showCreateClient={showCreateClientForm}
        />
      ) : (
        <CustomerForm
          showCreateClient={showCreateClientForm}
          setCustomerForm={handleCreateCustomer}
        />
      )}

      {!quotation.billingDate && (
        <Panel className={styles.headerText} headerText="Productos">
          <ResponsiveGridLayout
            columnsXL={2}
            columnsL={2}
            columnsM={1}
            columnsS={1}
          >
            <div>
              <Input
                showSuggestions
                placeholder="Listado de Productos"
                icon={<Icon name="product" />}
                onInput={(e) => {
                  getFilteredProduct(e);
                }}
                onChange={(e) => {
                  handleProductsComboBox(e);
                }}
                value={
                  product.quotationproductid
                    ? `${product.productid} ${product.quickoverview}`
                    : ""
                }
                style={{ width: "100%" }}
              >
                {allProducts.map((product: Product, i: number) => (
                  <SuggestionItem
                    key={i}
                    text={`${product.productid} ${product.quickoverview}`}
                  />
                ))}
              </Input>
            </div>
            <div>
              <Button
                endIcon={<AddIcon />}
                onClick={addProduct}
                className={styles.button}
                variant="outlined"
              >
                Agregar Producto
              </Button>
            </div>
            <div>
              <Input
                icon={<Icon name="hint" />}
                onInput={(e) =>
                  setProduct({ ...product, quickoverview: e.target.value })
                }
                placeholder="Descripción Rápida"
                className={styles.input}
                value={product.quickoverview ? product.quickoverview : ""}
                style={{
                  border: product.quickoverview === "" ? "2px solid red" : "",
                }}
              />
            </div>
            <div>
              <ComboBox
                icon={<Icon name="product" />}
                onChange={(e) => handleComboBoxChange(e, "productTypeId")}
                placeholder="Tipo de Producto"
                className={styles.input}
                value={getSelectedProductType()}
                style={{
                  border: product.producttypeid === 0 ? "2px solid red" : "",
                }}
              >
                {productTypes}
              </ComboBox>
            </div>
            <div>
              <ComboBox
                icon={<Icon name="product" />}
                onChange={(e) => handleComboBoxChange(e, "billingCodeId")}
                placeholder="Código de Facturación"
                className={styles.input}
                value={getSelectedBillingCode()}
                style={{
                  border: product.billingcodeid === 0 ? "2px solid red" : "",
                }}
              >
                {billingCodes}
              </ComboBox>
            </div>
            <div>
              <Input
                icon={<Icon name="legend" />}
                onInput={(e) =>
                  setProduct({
                    ...product,
                    detaileddescription: e.target.value,
                  })
                }
                placeholder="Descripción Detallada"
                className={styles.input}
                value={
                  product.detaileddescription ? product.detaileddescription : ""
                }
                style={{
                  border:
                    product.detaileddescription === "" ? "2px solid red" : "",
                }}
              />
            </div>
            <div>
              <Input
                type="Number"
                icon={<Icon name="number-sign" />}
                onInput={(e) =>
                  setProduct({ ...product, quantity: e.target.value })
                }
                placeholder="Cantidad"
                className={styles.input}
                value={product.quantity ? product.quantity : ""}
                style={{
                  border: product.quantity <= 0 ? "2px solid red" : "",
                }}
              />
            </div>
            <div>
              <Input
                type="Number"
                icon={<Icon name="lead" />}
                onInput={(e) =>
                  setProduct({ ...product, unitprice: e.target.value })
                }
                placeholder="Precio Unitario"
                className={styles.input}
                value={product.unitprice ? product.unitprice : ""}
                style={{
                  border: product.unitprice <= 0 ? "2px solid red" : "",
                }}
              />
            </div>
            <div>
              <Input
                type="Number"
                icon={<Icon name="waiver" />}
                onInput={(e) =>
                  setProduct({ ...product, discount: e.target.value })
                }
                placeholder="Descuento"
                className={styles.input}
                value={product.discount ? product.discount : ""}
              />
            </div>
            <div>
              <Input
                icon={<Icon name="lead-outdated" />}
                placeholder="Subtotal"
                className={styles.input}
                value={getProductSubtotal()}
                readonly
              />
            </div>
          </ResponsiveGridLayout>
        </Panel>
      )}
      <Panel className={styles.headerText} headerText="Listado de Productos">
        <Table
          columns={
            <>
              <TableColumn style={{ width: "12rem" }}>
                <Label>Código de Producto</Label>
              </TableColumn>
              <TableColumn
                demandPopin
                minWidth={800}
                popinText="Código de Facturación"
              >
                <Label>Código de Facturación</Label>
              </TableColumn>
              <TableColumn demandPopin minWidth={800} popinText="Tipo">
                <Label>Tipo</Label>
              </TableColumn>
              <TableColumn demandPopin minWidth={800} popinText="Cantidad">
                <Label>Cantidad</Label>
              </TableColumn>
              <TableColumn demandPopin minWidth={2000} popinText="Descripcion">
                <Label>Descripcion</Label>
              </TableColumn>
              <TableColumn
                demandPopin
                minWidth={800}
                popinText="Precio Unitario"
              >
                <Label>Precio Unitario</Label>
              </TableColumn>
              <TableColumn demandPopin minWidth={800} popinText="Descuento">
                <Label>Descuento</Label>
              </TableColumn>
              <TableColumn>
                <Label>Subtotal</Label>
              </TableColumn>
              {!quotation.billingDate && quotationsShippings.length === 0 && (
                <TableColumn>
                  <Label>Acciones</Label>
                </TableColumn>
              )}
            </>
          }
          style={{
            border: products.length === 0 ? "2px solid red" : "",
          }}
        >
          {Object.values(record).map((product, i) => (
            <TableRow key={i}>
              <TableCell>
                <Label>{product.productid}</Label>
              </TableCell>
              <TableCell>
                <Label>
                  {billingCodes &&
                    billingCodes.filter(
                      (billingcode: any) =>
                        billingcode.key === product.billingcodeid.toString()
                    )[0].props.text}
                </Label>
              </TableCell>
              <TableCell>
                <Label>
                  {productTypes &&
                    productTypes.filter(
                      (productType: any) =>
                        productType.key === product.producttypeid.toString()
                    )[0].props.text}
                </Label>
              </TableCell>
              <TableCell>
                <Label>{product.quantity}</Label>
              </TableCell>
              <TableCell>
                <Label>{product.quickoverview}</Label>
              </TableCell>
              <TableCell>
                <Label>{product.unitprice}</Label>
              </TableCell>
              <TableCell>
                <Label>{`${product.discount}%`}</Label>
              </TableCell>
              <TableCell>
                <Label>{product.subtotal}</Label>
              </TableCell>
              {!quotation.billingDate && quotationsShippings.length === 0 && (
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => deleteProduct(product)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </Panel>
      {quotation.quotationTypeId === 2 && (
        <Panel headerText="Complemento de Exportacion">
          <ResponsiveGridLayout
            columnsXL={2}
            columnsL={2}
            columnsM={1}
            columnsS={1}
          >
            <div>
              <Input
                icon={<Icon name="paper-plane" />}
                onInput={(e) => handleInputChange(e, "buyersName")}
                placeholder="Nombre de Consignatario"
                className={styles.input}
                style={{
                  border: quotation.buyersName === "" ? "2px solid red" : "",
                }}
                value={quotation.buyersName}
                maxlength={68}
              />
            </div>
            <div>
              <Input
                icon={<Icon name="addresses" />}
                onInput={(e) => handleInputChange(e, "buyersAddress")}
                placeholder="Dirección de Consignatario"
                className={styles.input}
                style={{
                  border: quotation.buyersAddress === "" ? "2px solid red" : "",
                }}
                value={quotation.buyersAddress}
                maxlength={68}
              />
            </div>
            <div>
              <ComboBox
                icon={<Icon name="globe" />}
                onChange={(e) => handleComboBoxChange(e, "incotermId")}
                placeholder="INCOTERM"
                className={styles.input}
                value={getSelectedIncoterm()}
                style={{
                  border: quotation.incotermId === 0 ? "2px solid red" : "",
                }}
              >
                {incoterms}
              </ComboBox>
            </div>
          </ResponsiveGridLayout>
        </Panel>
      )}
      {quotation.quotationTypeId === 1 && (
        <Panel headerText="Entrega">
          <ResponsiveGridLayout
            columnsXL={2}
            columnsL={2}
            columnsM={1}
            columnsS={1}
          >
            <div>
              <DatePicker
                className={styles.input}
                onChange={(e) => handleInputChange(e, "dateOfDelivery")}
                primaryCalendarType="Gregorian"
                formatPattern={"yyyy-MM-dd"}
                placeholder="Fecha de Entrega"
                value={quotation.dateOfDelivery?.split("T")[0]}
                style={{
                  border:
                    quotation.dateOfDelivery === "" ? "2px solid red" : "",
                }}
              />
            </div>
            <div>
              <Input
                icon={<Icon name="addresses" />}
                onInput={(e) => handleInputChange(e, "deliveryAddress")}
                placeholder="Dirección Entrega"
                className={styles.input}
                style={{
                  border:
                    quotation.deliveryAddress === "" ? "2px solid red" : "",
                }}
                value={quotation.deliveryAddress}
              />
            </div>
            <div>
              <ComboBox
                icon={<Icon name="shipping-status" />}
                onChange={(e) => handleComboBoxChange(e, "deliveryMethodId")}
                placeholder="Forma de Entrega"
                className={styles.input}
                value={getSelectedDeliveryMethod()}
                style={{
                  border:
                    quotation.deliveryMethodId === 0 ? "2px solid red" : "",
                }}
              >
                {deliveryMethods}
              </ComboBox>
            </div>
            <div>
              <ComboBox
                icon={<Icon name="burglary" />}
                onChange={(e) => handleComboBoxChange(e, "warrantyId")}
                placeholder="Garantía"
                className={styles.input}
                value={getSelectedWarranty()}
                style={{
                  border: quotation.warrantyId === 0 ? "2px solid red" : "",
                }}
              >
                {guarantee}
              </ComboBox>
            </div>
            <div>
              <Input
                icon={<Icon name="show" />}
                onInput={(e) => handleInputChange(e, "observations")}
                placeholder="Observaciones de Entrega"
                className={styles.input}
                style={{
                  border: quotation.observations === "" ? "2px solid red" : "",
                }}
                value={quotation.observations}
              />
            </div>
          </ResponsiveGridLayout>
        </Panel>
      )}
      {quotationsShippings.length > 0 && (
        <Panel headerText="Envios">
          {quotationsShippings.map((shipping, i) => (
            <Badge
              key={i}
              icon={<Icon name="shipping-status" />}
              style={{ margin: "4px" }}
              onClick={() =>
                viewFile(`${API_URL}/external/shipping/${shipping.shippingid}`)
              }
            >
              {shipping.shippingid}
            </Badge>
          ))}
        </Panel>
      )}
      <Panel headerText="Resumen ">
        <ResponsiveGridLayout
          columnsXL={2}
          columnsL={2}
          columnsM={1}
          columnsS={1}
        >
          <div>
            <strong>Autor</strong>
          </div>
          <div>
            <Input
              readonly
              className={styles.input}
              value={quotation.nickname ? quotation.nickname : ""}
            />
          </div>
          <div>
            <strong>Subtotal</strong>
          </div>
          <div>
            <Input
              readonly
              className={styles.input}
              value={`${quotation.totalWithoutVat.toFixed(2).toString()} ${
                quotation.currencyId === 1 ? "QTZ" : "USD"
              }`}
            />
          </div>
          <div>
            <strong>IVA</strong>
          </div>
          <div>
            <Input
              readonly
              className={styles.input}
              value={`${quotation.totalWithVat.toFixed(2)} ${
                quotation.currencyId === 1 ? "QTZ" : "USD"
              }`}
            />
          </div>
          <div>
            <strong>Total</strong>
          </div>
          <div>
            <Input
              readonly
              className={styles.input}
              value={`${quotation.grandTotal.toFixed(2)} ${
                quotation.currencyId === 1 ? "QTZ" : "USD"
              }`}
            />
          </div>
          <div>
            <Button
              endIcon={<EditIcon />}
              variant="contained"
              onClick={() => {
                saveQuotation();
              }}
              className={styles.button}
              disabled={
                (quotation.billingDate ? true : false) ||
                quotationsShippings.length > 0 ||
                (cfFlag === true && quotation.quotationId === 0)
              }
            >
              {quotation.quotationId === 0
                ? "Crear Proforma"
                : "Modificar Proforma"}
            </Button>
          </div>
          {quotation.quotationId !== 0 && (
            <div>
              <Button
                endIcon={<ManageSearchIcon />}
                onClick={showQuotation}
                className={styles.button}
                variant="contained"
              >
                Visualizar Proforma
              </Button>
            </div>
          )}
          {quotation.quotationId !== 0 && (
            <div>
              <Button
                endIcon={<AttachMoneyIcon />}
                onClick={handleOpenBillingDatePicker}
                className={styles.button}
                disabled={
                  billing || userCookie.user.toString() !== "1" || cfFlag
                }
                variant="contained"
              >
                {quotation.billingDate ? "Ver Factura" : "Facturar"}
              </Button>
              {billing && <Loader />}
              {errorMessage && (
                <MessageStrip hideCloseButton design="Negative">
                  `${errorMessage}`
                </MessageStrip>
              )}
            </div>
          )}
          {quotation.quotationId !== 0 && quotation.billingDate && (
            <div>
              <Button
                endIcon={<PrintIcon />}
                onClick={printBill}
                className={styles.button}
                variant="contained"
                disabled={userCookie.user.toString() !== "1"}
              >
                {"Imprimir Factura"}
              </Button>
            </div>
          )}
          {quotation.quotationId !== 0 && (
            <div>
              <Button
                endIcon={<AddIcon />}
                onClick={() => {
                  restoreQuotationState();
                }}
                className={styles.button}
                variant="contained"
              >
                Nueva Proforma
              </Button>
            </div>
          )}
        </ResponsiveGridLayout>
      </Panel>
      <Dialog
        ref={showMessage}
        footer={
          <div style={{ padding: "5px" }}>
            <Button
              onClick={() => showMessage.current?.close()}
              variant="contained"
            >
              Cerrar
            </Button>
          </div>
        }
        headerText={messageTitle}
      >
        <div style={{ padding: "5px" }}>{messageContent}</div>
      </Dialog>
      <>
        {products.length > 0 && (
          <Modal
            open={createBillConfirmation}
            onClose={() => setCreateBillConfirmation(false)}
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90%",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className={styles.resumen}>Resumen de Factura</p>
              <TableToolbar bill={billinfo} />
              <TableComponent
                tableHeader={[
                  "Codigo de Producto",
                  "Codigo de Facturacion",
                  "Descripcion",
                  "Cantidad",
                  "Precio Unitario",
                  "Descuento",
                  "Subtotal",
                ]}
                tableBody={productsView}
                sx={{ marginTop: "0.1rem", padding: "1rem" }}
              />
              <Button
                variant="contained"
                color="success"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "30%",
                  alignSelf: "center",
                }}
                onClick={handleCreateBillConfirmation}
              >
                Confirmar
              </Button>
            </Box>
          </Modal>
        )}
      </>
      <Popover
        className={styles.popover}
        ref={popoverRef}
        footer={
          <Bar
            endContent={
              <Button onClick={handleCloseBillingDatePicker}>Cerrar</Button>
            }
          />
        }
        header={
          <Bar className={styles.popheader}>
            <Title>Fecha de Facturacion</Title>
          </Bar>
        }
        placementType="Top"
      >
        <List>
          <StandardListItem
            icon="calendar"
            additionalText={cleanBillingDate(new Date())}
            onClick={handleBillingDatePicker(1)}
          >
            Facturar Hoy
          </StandardListItem>
          {new Date(Date.now() + 3600 * 1000 * 24).getMonth() -
            new Date().getMonth() <
            1 && (
            <StandardListItem
              icon="calendar"
              additionalText={cleanBillingDate(
                new Date(Date.now() + 3600 * 1000 * 24)
              )}
              onClick={handleBillingDatePicker(2)}
            >
              Facturar Mañana
            </StandardListItem>
          )}
        </List>
      </Popover>
      <SnackBar
        message={notificationMessage}
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        severity={notificationType}
      />
    </div>
  );
}
