import styles from "./Annulment.module.css";

import {
  ResponsiveGridLayout,
  Input,
  Icon,
  Button,
  Panel,
  ComboBox,
  ComboBoxItem,
  TextArea,
  Dialog,
  MessageBox,
  MessageBoxActions,
  Loader,
  MessageStrip,
} from "@ui5/webcomponents-react";
import { useState, useEffect, useRef } from "react";
import { AnnulmentDTO, annulmentInitialState } from "../../models/AnnulmentDTO";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";
import { useCookies } from "react-cookie";

export function Annulment() {
  /**
   * Annulment global props
   */
  const [annulmentOutOfDate, setAnnulmentOutOfDate] = useState<boolean>(false);
  const [seriesNumberSearch, setSeriesNumberSearch] = useState<string>("");
  const [authorizationNumberSearch, setAuthorizationNumberSearch] =
    useState<string>("");
  const [annulment, setAnnulment] = useState<AnnulmentDTO>({
    ...annulmentInitialState,
  });
  const [annulmentReasons, setAnnulmentReasons] = useState<any[]>([]);
  const showMessage = useRef<Ui5DialogDomRef>(null);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [createAnnulmentConfirmation, setCreateAnnulmentConfirmation] =
    useState(false);
  const [annuling, setAnnuling] = useState(false);
  const [cookie] = useCookies(["access"]);
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie["access"]}` },
  };

  /**
   * Gets initial data for global props
   */
  useEffect(() => {
    const source = axios.CancelToken.source();
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie["access"]}` },
    };
    const fetchMasterData = async () => {
      try {
        const annulmentReasonsResponse = await axios.get(
          `${API_URL}/crud/annulmentReason`,
          { cancelToken: source.token, ...apiAccess }
        );
        setAnnulmentReasons(annulmentReasonsResponse.data);
      } catch (error) {}
    };
    fetchMasterData();
    return () => {
      source.cancel();
    };
  }, [cookie]);

  /**
   * Restores annulment initial state
   */
  const restoreInitialState = () => {
    setSeriesNumberSearch("");
    setAuthorizationNumberSearch("");
    setAnnulment({ ...annulmentInitialState });
  };

  /**
   * Searches exchange bill data
   * @param documentSeries Document series
   * @param documentNumber Document number
   * @param authorizationNumber Document authorization number
   * @returns Echange bill data
   */
  const searchBillRequest = async (
    documentSeries: string,
    documentNumber: string,
    authorizationNumber: string
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/search-bill`,
        {
          documentSeries: documentSeries,
          documentNumber: documentNumber,
          authorizationNumber: authorizationNumber,
        },
        apiAccess
      );
      return [response.data.rows[0], null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Searches annulment data
   * @param documentSeries Document series
   * @param documentNumber Document number
   * @param authorizationNumber Document authorization number
   * @returns Annulment data data
   */
  const searchAnnulmentRequest = async (
    documentSeries: string,
    documentNumber: string,
    authorizationNumber: string
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/search-annulment`,
        {
          documentSeries: documentSeries,
          documentNumber: documentNumber,
          authorizationNumber: authorizationNumber,
        },
        apiAccess
      );
      return [response.data.rows[0], null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Handles annument search
   */
  const handleSearchDocument = async () => {
    const documentNumber = seriesNumberSearch.split("-")[0]
      ? seriesNumberSearch.split("-")[0]
      : "";
    const documentSeries = seriesNumberSearch.split("-")[1]
      ? seriesNumberSearch.split("-")[1]
      : "";
    const documentAuthorizationNumber = authorizationNumberSearch;
    const [annulmentData] = await searchAnnulmentRequest(
      documentNumber,
      documentSeries,
      documentAuthorizationNumber
    );
    if (annulmentData) {
      setAnnulment({ ...annulment, ...annulmentData });
      setAnnulmentOutOfDate(false);
      return;
    }
    const [billData] = await searchBillRequest(
      documentNumber,
      documentSeries,
      documentAuthorizationNumber
    );
    if (billData) {
      const currentDate = new Date();
      if (currentDate.getDate() >= 20) {
        let emissionDate = new Date(billData.emissiondate.toString());
        if (currentDate.getMonth() - emissionDate.getMonth() !== 0) {
          setAnnulmentOutOfDate(true);
          restoreInitialState();
          console.log(annulment);
        } else {
          setAnnulmentOutOfDate(false);
        }
      }
      setAnnulment({ ...annulment, ...billData, annulmentid: 0 });
      return;
    }
    restoreInitialState();
  };

  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result.toLocaleString("es-GT").split(" ")[0];
  };

  /**
   * Handlers annulment reason name
   * @param annulmentReasonName Annulment reason name
   */
  const handleAnnulmentReasonChange = (annulmentReasonName: string) => {
    for (let i = 0; i < annulmentReasons.length; i++) {
      const annulmentReason = annulmentReasons[i];
      if (annulmentReason.name.toString() === annulmentReasonName) {
        setAnnulment({
          ...annulment,
          annulmentreasonid: annulmentReason.annulmentreasonid,
        });
        return;
      }
    }
  };

  /**
   * Gets annulment reason name
   * @returns Annulment reason name
   */
  const getAnnulmentReasonValue = (): string => {
    if (annulment.annulmentid !== 0) {
      for (let i = 0; i < annulmentReasons.length; i++) {
        const annulmentReason = annulmentReasons[i];
        if (annulmentReason.annulmentreasonid === annulment.annulmentreasonid) {
          return annulmentReason.name;
        }
      }
    }
    return "";
  };

  /**
   * Handles annulment request
   */
  const annulmentRequest = async (annulmentData: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/external/annulment`,
        annulmentData,
        apiAccess
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Handles annulment operation
   */
  const handleAnnulment = async () => {
    if (annulment.annulmentid !== 0) {
      viewFile(
        `${API_URL}/external/annulment/${annulment.billtype}/${annulment.annulmentid}`
      );
      return;
    }
    if (annulment.annulmentreasonid === 0) {
      displayMessage("Error", "Selecciona el motivo de anulación");
      return;
    }
    setCreateAnnulmentConfirmation(true);
  };

  /**
   * Handles combobox billing confirmation
   * @param event Message box confirmation
   */
  const handleCreateAnnulmentConfirmation = async (event: any) => {
    if (event.detail.action === MessageBoxActions.OK) {
      setCreateAnnulmentConfirmation(false);
      setAnnuling(true);
      const annulmentData: any = { ...annulment };
      delete annulmentData.annulmentid;
      delete annulmentData.annulmentdate;
      const [annulmentRequestData] = await annulmentRequest(annulmentData);
      if (!annulmentRequestData) {
        displayMessage(
          "Error anulando documento",
          "Comunicate con el soporte IT."
        );
        setAnnuling(false);
        return;
      }
      setAnnulment({ ...annulment, ...annulmentRequestData.data[0] });
      displayMessage(
        "Anulación generada",
        `Anulación #${annulmentRequestData.data[0].annulmentid} generada.`
      );
      setAnnuling(false);
    }
    setCreateAnnulmentConfirmation(false);
  };

  /**
   * Displays a dialog message
   * @param title Message title
   * @param content Message content
   */
  const displayMessage = (title: string, content: string) => {
    setMessageTitle(title);
    setMessageContent(content);
    showMessage.current?.show();
  };

  /**
   * Displays annulment rendered data
   */
  const showAnnulment = () => {
    if (annulment.annulmentid !== 0) {
      viewFile(
        `${API_URL}/external/annulment/${annulment.billtype}/${annulment.annulmentid}`
      );
    }
  };

  /**
   * Prints annulment rendered data
   */
  const printAnnulment = () => {
    if (annulment.annulmentid !== 0) {
      viewFile(
        `${API_URL}/external/annulmentPrint/${annulment.billtype}/${annulment.annulmentid}`
      );
    }
  };

  /**
   * Opens an file
   * @param url File url
   */
  const viewFile = async (url: any) => {
    fetch(url, apiAccess)
      .then((response) => response.blob())
      .then((blob) => {
        let _url: any = window.URL.createObjectURL(blob);
        window.open(_url, "_blank")?.focus();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={styles.annulment}>
      <Panel headerText="Búsqueda de DTE">
        <ResponsiveGridLayout
          columnsXL={3}
          columnsL={2}
          columnsM={1}
          columnsS={1}
        >
          <div>
            Serie-Documento
            <Input
              icon={<Icon name="number-sign" />}
              onInput={(e) => setSeriesNumberSearch(e.target.value.toString())}
              className={styles.input}
              placeholder="Serie-Documento"
              value={seriesNumberSearch}
            />
          </div>
          <div>
            Número de Autorización
            <Input
              icon={<Icon name="number-sign" />}
              onInput={(e) =>
                setAuthorizationNumberSearch(e.target.value.toString())
              }
              className={styles.input}
              placeholder="Número de Autorización"
              value={authorizationNumberSearch}
            />
            <Button
              icon="search"
              onClick={handleSearchDocument}
              className={styles.button}
            >
              Buscar Documento
            </Button>
          </div>
          <div></div>
        </ResponsiveGridLayout>
      </Panel>
      <Panel headerText="Información de Documento">
        <ResponsiveGridLayout
          columnsXL={2}
          columnsL={2}
          columnsM={1}
          columnsS={1}
        >
          <div>
            <strong>Numero de Autorización</strong>
            <Input
              icon={<Icon name="number-sign" />}
              className={styles.input}
              readonly
              value={
                annulment.authorizationnumber
                  ? annulment.authorizationnumber
                  : ""
              }
            />
          </div>
          <div>
            <strong>Serie-Documento</strong>
            <Input
              icon={<Icon name="number-sign" />}
              onInput={function noRefCheck() {}}
              className={styles.input}
              readonly
              value={
                annulment.documentseries
                  ? `${annulment.documentseries}-${annulment.documentnumber}`
                  : ""
              }
            />
          </div>
          <div>
            <strong>Tipo de DTE y Estado</strong>
            <Input
              icon={<Icon name="document" />}
              className={styles.input}
              readonly
              value={`${annulment.billtype}${
                annulment.billtype !== ""
                  ? annulment.annulmentid === 0
                    ? " - Activo"
                    : " - Inactivo"
                  : ""
              }`}
            />
          </div>
          <div>
            <strong>Fecha de Emisión</strong>
            <Input
              icon={<Icon name="date-time" />}
              className={styles.input}
              readonly
              value={
                annulment.emissiondate ? cleanDate(annulment.emissiondate) : ""
              }
            />
          </div>
          <div>
            <strong>NIT del Receptor</strong>
            <Input
              icon={<Icon name="employee" />}
              onInput={function noRefCheck() {}}
              className={styles.input}
              readonly
              value={annulment.receivernit ? annulment.receivernit : ""}
            />
          </div>
          <div>
            <strong>Nombre del Receptor</strong>
            <Input
              icon={<Icon name="retail-store" />}
              onInput={function noRefCheck() {}}
              className={styles.input}
              readonly
              value={
                annulment.receiverfullname ? annulment.receiverfullname : ""
              }
            />
          </div>
          <div>
            <strong>Comentarios</strong>
            <TextArea
              className={styles.input}
              onInput={(e) =>
                setAnnulment({
                  ...annulment,
                  comments: e.target.value.toString(),
                })
              }
            />
          </div>
          <div>
            <strong>Razón de Anulación</strong>
            <ComboBox
              icon={<Icon name="cause" />}
              onChange={(e) => handleAnnulmentReasonChange(e.target.value)}
              placeholder="Razón de Anulación"
              className={styles.input}
              value={getAnnulmentReasonValue()}
              style={{
                border:
                  annulment.annulmentreasonid === 0 ? "2px solid red" : "",
              }}
            >
              {annulmentReasons.map((annulmentReason, i) => (
                <ComboBoxItem
                  key={annulmentReason.annulmentreasonid}
                  text={annulmentReason.name}
                />
              ))}
            </ComboBox>
          </div>
          <div>
            <Button
              icon="delete"
              onClick={handleAnnulment}
              iconEnd
              className={styles.button}
              design="Negative"
              disabled={
                annuling || annulment.annulmentid !== 0 || annulmentOutOfDate
              }
            >
              Anular
            </Button>
            {annuling && <Loader />}
          </div>
          {annulmentOutOfDate && (
            <MessageStrip design="Negative" hideCloseButton>
              Documento fuera del plazo para generar la anulación
            </MessageStrip>
          )}
          {annulment.annulmentid !== 0 && (
            <div>
              <Button
                icon="detail-view"
                onClick={showAnnulment}
                iconEnd
                className={styles.button}
                design="Positive"
              >
                Visualizar Anulación
              </Button>
            </div>
          )}
          {annulment.annulmentid !== 0 && (
            <div>
              <Button
                icon="print"
                onClick={printAnnulment}
                iconEnd
                className={styles.button}
                design="Positive"
              >
                Imprimir Anulación
              </Button>
            </div>
          )}
        </ResponsiveGridLayout>
      </Panel>
      <Dialog
        ref={showMessage}
        footer={
          <div style={{ padding: "5px" }}>
            <Button
              onClick={() => showMessage.current?.close()}
              design="Emphasized"
            >
              Cerrar
            </Button>
          </div>
        }
        headerText={messageTitle}
      >
        <div style={{ padding: "5px" }}>{messageContent}</div>
      </Dialog>
      <>
        <MessageBox
          open={createAnnulmentConfirmation}
          onClose={handleCreateAnnulmentConfirmation}
          actions={[MessageBoxActions.OK, MessageBoxActions.Cancel]}
        >
          ¿Desea anular el documento?
        </MessageBox>
      </>
    </div>
  );
}
