import styles from "./Login.module.css";

import React from "react";

import {
	Form,
	FormItem,
	Input,
	Icon,
	Button,
	Dialog,
} from "@ui5/webcomponents-react/";
import { useRef, useState, useEffect } from "react";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";

export function Login() {
	/**
	 * Login global props
	 */
	const userInitialState = { username: "", password: "" };
	const [user, setUser] = useState<any>(userInitialState);
	const showMessage = useRef<Ui5DialogDomRef>(null);
	const [messageTitle, setMessageTitle] = useState<string>("");
	const [messageContent, setMessageContent] = useState<string>("");
	const [cookie, setCookie] = useCookies(["access"]);
	const [userCookie, setUserCookie] = useCookies(["user"]);
	const [nicknameCookie, setNicknameCookie] = useCookies(["nickname"]);
	const history = useHistory();

	/**
	 * Logins to the portal
	 * @returns Login response data or an error
	 */
	const loginRequest = async () => {
		try {
			const response = await axios.post(`${API_URL}/login`, {
				username: user.username,
				password: user.password,
			});
			return [response.data, null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Handles login
	 */
	const handleLogin = async () => {
		if (user.username === "") {
			displayMessage("Error", "Ingrese su nombre de usuario");
			return;
		}
		if (user.password === "") {
			displayMessage("Error", "Ingrese su contraseña");
			return;
		}
		const [loginData] = await loginRequest();
		if (!loginData) {
			displayMessage("Error", "Credenciales inválidas");
			return;
		}
		setUserCookie("user", loginData._userType, { path: "/" });
		setNicknameCookie("nickname", loginData.nickname, { path: "/" });
		setCookie("access", loginData.accessToken, { path: "/" });
		history.push("/");
	};

	/**
	 * Displays a dialog message
	 * @param title Message title
	 * @param content Message content
	 */
	const displayMessage = (title: string, content: string) => {
		setMessageTitle(title);
		setMessageContent(content);
		showMessage.current?.show();
	};

	useEffect(() => {
		if (
			cookie["access"] !== undefined &&
			userCookie["user"] !== undefined &&
			nicknameCookie["nickname"] !== undefined
		) {
			history.push("/");
		}
	}, [cookie, history, userCookie, nicknameCookie]);

	return (
		<div className={styles.login}>

			<Form
				titleText="Iniciar Sesión"
				columnsXL={1}
				columnsL={1}
				columnsM={1}
				columnsS={1}
			>
				<FormItem label="Usuario">
					<Input
						icon={<Icon name="email" />}
						onInput={(e) =>
							setUser({ ...user, username: e.target.value.toString() })
						}
						className={styles.input}
						type="Text"
					/>
				</FormItem>
				<FormItem label="Contraseña">
					<Input
						icon={<Icon name="key" />}
						onInput={(e) =>
							setUser({ ...user, password: e.target.value.toString() })
						}
						className={styles.input}
						type="Password"
					/>
				</FormItem>
				<div />
				<FormItem>
					<Button
						icon="initiative"
						onClick={handleLogin}
						className={styles.button}
						iconEnd
						design="Emphasized"
					>
						Entrar
					</Button>
				</FormItem>
			</Form>
			<Dialog
				ref={showMessage}
				footer={
					<div style={{ padding: "5px" }}>
						<Button
							onClick={() => showMessage.current?.close()}
							design="Emphasized"
						>
							Cerrar
						</Button>
					</div>
				}
				headerText={messageTitle}
			>
				<div style={{ padding: "5px" }}>{messageContent}</div>
			</Dialog>
		</div>
	);
}
