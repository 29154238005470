import { Box, Button, CircularProgress } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoadingTable from "../loading-table/LoadingTable";
import MaterialReactTable from "material-react-table";
import ShowError from "../show-error/ShowError";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";
import getFields from "./Fields";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import api from "../../app/interceptor";
import { CSVLink } from "react-csv";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FilterBillTable from "./FilterBillTable";
import Pagination from "../pagination/Pagination";
import { SnackBar } from "../snack-bar/SnackBar";

const BillNewReport = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [isFetching, setIsFetching] = useState(false);

  const [filter, setFilter] = useState({});
  const dataRaw = useFetch("/bill/report", {
    pageIndex: pageIndex,
    pageSize: pageSize,
    filter: filter,
  });

  const [quotationId, setQuotationId] = useState(undefined);
  const [showFilter, setShowFilter] = useState(false);
  const [action, setAction] = useState("");
  const columns: any[] = useMemo(
    () => getFields(setQuotationId, setAction),
    []
  );
  const bills: any = useMemo(() => dataRaw.data, [dataRaw.data]);
  const [dataBills, setDataBills] = useState([]);

  // Error messages
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("");

  useEffect(() => {
    if (!bills) return;

    if (quotationId) {
      const bill: any = bills.find(
        (item: any) => item.quotationid === quotationId
      );

      if (!bill) return;

      switch (action) {
        case "0":
          saveAs(
            new Blob([bill.certifieddocument], { type: "text/xml" }),
            `bill-${bill.authorizationnumber}.xml`
          );
          break;
        case "1":
          viewFile(`/external/quotation/${quotationId}`);
          break;
        case "2":
          openBill(bill);
          break;
        case "3":
          billPrint(bill);
          break;
      }
    }
    setAction(""); // this let me call the event multiple times ( maybe must find  better solution )
  }, [quotationId, action]);

  useEffect(() => {
    dataRaw.refetch({});
    setPageIndex(0);
  }, [filter]);

  useEffect(() => {
    dataRaw.refetch({});
  }, [pageSize, pageIndex]);

  useEffect(() => {
    if (dataBills.length > 0) {
      setIsFetching(false);
      csvLinkRef?.current?.link.click();
    }
  }, [dataBills]);

  const onChangeFilter = (values: any) => {
    console.log("values: ", values);
    if (Object.keys(values).length === 0) {
      setFilter({});
      return;
    }
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || values[key] === undefined) {
        delete values[key];
      }
    });

    setFilter({ ...filter, ...values });
  };

  const openBill = (bill: any) => {
    switch (bill.billtype) {
      case "FCAM":
        if (!bill.annulmentid) {
          viewFile(`/external/exchangeBill/${bill.quotationid}`);
        } else {
          viewFile(`/external/annulment/FCAM/${bill.annulmentid}`);
        }
        break;
      case "FACT":
        if (!bill.annulmentid) {
          viewFile(`/external/exportBill/${bill.quotationid}`);
        } else {
          viewFile(`/external/annulment/FACT/${bill.annulmentid}`);
        }
        break;
    }
  };

  const billPrint = (bill: any) => {
    console.log(bill);
    switch (bill.billtype) {
      case "FCAM":
        if (!bill.annulmentid) {
          try {
            handlePrintBill(`/external/exchangeBillPrint/${bill.quotationid}`);
          } catch (error) {
            console.log(error);
          }
        } else {
          viewFile(`/external/annulmentPrint/FCAM/${bill.annulmentid}`);
        }
        break;
      case "FACT":
        if (!bill.annulmentid) {
          try {
            handlePrintBill(`/external/exportBillPrint/${bill.quotationid}`);
          } catch (error) {
            console.log(error);
          }
        } else {
          viewFile(`/external/annulmentPrint/FACT/${bill.annulmentid}`);
        }
        break;
    }
  };

  /**
   * Handle download bill pdf
   * @param url the api endpoint url
   */
  const handlePrintBill = async (url: string) => {
    await api
      .get(url, { responseType: "blob" })
      .then((response) => {
        if (!response.status) return;
        let disposition = response.headers["content-disposition"];
        console.log(disposition);
        const dispositonSplit = disposition.split("=");
        const name = dispositonSplit[1].replaceAll('"', "");
        saveAs(
          new Blob([response.data], { type: "application/pdf;charset=UTF-8" }),
          `${name}.pdf`
        );
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  if (dataRaw.status === "loading") {
    return <LoadingTable />;
  }

  /**
   * Opens an file
   * @param url File url
   */
  const viewFile = async (url: any) => {
    try {
      const response = await api.get(url, { responseType: "blob" });
      let _url: any = window.URL.createObjectURL(response.data);
      window.open(_url, "_blank")?.focus();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Cleans bills data
   * @returns Cleaned bills dat.rowsa
   */
  const cleanReportData = (): any[] => {
    let exportedBills = [...dataBills].map((item: any) => ({ ...item }));
    return exportedBills.map((bill: any) => {
      bill.certificationdate = cleanDate(bill.certificationdate);
      delete bill.certifieddocument;
      return bill;
    });
  };

  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result.toLocaleString("es-GT").split(" ")[0];
  };

  const fetchCsv = async () => {
    try {
      setIsFetching(true);
      const data = await api.get("/bill/downloadReport");
      setDataBills(data.data);
    } catch (error) {
      console.error(error);
      setOpenAlert(true);
      setMessageAlert(
        "Ha ocurrido un problema al intentar descargar el reporte. Comunicate con IT"
      );
      setTypeAlert("error");
    } finally {
      setIsFetching(false);
    }
  };

  if (dataRaw.status === "error") {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Button
          sx={{ marginRight: "auto", marginBottom: 2 }}
          onClick={() => history.push("/reports")}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <ShowError
          title="Error Al Cargar el Reporte"
          type="error"
          message="Ha ocurrido un error inesperado al cargar la informacion del reporte."
        />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 4 }}>
      <Box sx={{ display: "flex" }}>
        <Button
          sx={{ marginRight: "auto", marginBottom: 2 }}
          onClick={() => history.push("/reports")}
          variant="contained"
        >
          <ArrowBackIosNewIcon />
          Atras
        </Button>

        <CSVLink
          ref={csvLinkRef}
          filename={`quotation-report-${new Date().toLocaleString("es-GT", {
            timeZone: "America/Guatemala",
          })}`}
          data={cleanReportData()}
          style={{ display: "none" }}
        />

        {!isFetching ? (
          <Button
            variant="contained"
            onClick={fetchCsv}
            size="small"
            sx={{ marginBottom: 2, width: "auto" }}
          >
            <FileDownloadIcon />
            Descargar CSV
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: showFilter ? "80%" : "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "fit",
            }}
          >
            <Button
              sx={{ fontSize: "1.5rem", marginLeft: "auto" }}
              onClick={() => setShowFilter(!showFilter)}
              variant="contained"
              color="secondary"
            >
              Filtrar
              {!showFilter ? (
                <FilterListIcon sx={{ fontSize: "3rem" }} />
              ) : (
                <FilterListOffIcon sx={{ fontSize: "3rem" }} />
              )}
            </Button>
          </Box>

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPageIndex={setPageIndex}
          />
          <MaterialReactTable
            columns={columns}
            data={bills}
            positionPagination="top"
            enablePagination={false}
            enableFilters={false}
            enableSorting={true}
            enableColumnVirtualization={true}
            enableRowVirtualization
            muiTableContainerProps={{ sx: { maxHeight: "" } }}
            enableTopToolbar={true}
            enableColumnActions={true}
            rowNumberMode="original"
            muiTableBodyRowProps={{ hover: true }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          {showFilter && (
            <FilterBillTable
              onChangeFilter={onChangeFilter}
              fields={[
                {
                  id: "quotationid",
                  name: "quotationid",
                  label: "Número de Proforma",
                  placeholder: "Busca por #Proforma",
                  type: "number",
                },
                {
                  id: "productionorder",
                  name: "productionorder",
                  label: "Orden de Produccion",
                  placeholder: "#Ord. Produccion",
                  type: "string",
                },
                {
                  id: "customer",
                  name: "customer",
                  label: "Nombre del Cliente",
                  placeholder: "Nombre de Cliente",
                  type: "string",
                },
                {
                  id: "nit",
                  name: "nit",
                  label: "Nit del Cliente",
                  placeholder: "#NIT",
                  type: "string",
                },
                {
                  id: "grandtotal",
                  name: "grandtotal",
                  label: "Monto Total",
                  placeholder: "Total Factura",
                  type: "number",
                },
              ]}
            />
          )}
        </Box>
      </Box>

      <SnackBar
        message={messageAlert}
        open={openAlert}
        setOpen={setOpenAlert}
        severity={typeAlert}
      />
    </Box>
  );
};

export default BillNewReport;
