
export interface BillInfo {
  authorizationnumber: string;
  serienumber: string;
  documentnumber: string;
  customeremail: string;
  customernit: string;
  emissiondate: Date;
  certificationdate: Date;
  customername: string;
  billtype: string;
  billstatus: string;
  currency: string;
}

export const billInfoInitalValue: BillInfo = {
  authorizationnumber: "",
  serienumber: "",
  documentnumber: "",
  customeremail: "",
  customernit: "",
  emissiondate: new Date(),
  certificationdate: new Date(),
  customername: "",
  billtype: "",
  billstatus: "",
  currency: "",
}
