import styles from "./Product.module.css";
import {
  Form,
  FormItem,
  Input,
  Icon,
  TextArea,
  ComboBox,
  ComboBoxItem,
  Button,
  Dialog,
} from "@ui5/webcomponents-react";

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { ProductDTO, productInitialState } from "../../models/ProductDTO";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";
import { useCookies } from "react-cookie";

import { Box } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DuplicateProducts from "./DuplicateProducts";

export function Product() {
  /**
   * Product global state and searching
   */
  const [product, setProduct] = useState<ProductDTO>(productInitialState);
  const [productSearch, setProductSearch] = useState("");
  const [allProducts, setAllProducts] = useState<any[]>([]);

  const [duplicate, setDuplicate] = useState<any[]>([]);
  const [mustShow, setMustShow] = useState(false);

  /**
   * Product properties
   */
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [billingCodes, setBillingCodes] = useState<any[]>([]);
  const showMessage = useRef<Ui5DialogDomRef>(null);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [cookie] = useCookies(["access"]);
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie["access"]}` },
  };

  /**
   * Load initial data to global props
   */
  useEffect(() => {
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie["access"]}` },
    };
    axios
      .get(`${API_URL}/crud/productType`, apiAccess)
      .then((response: any) => {
        const serverProductTypes = response.data.map((productType: any) => (
          <ComboBoxItem
            key={productType.producttypeid}
            text={productType.name}
          />
        ));
        setProductTypes(serverProductTypes);
      });
    axios
      .get(`${API_URL}/crud/billingCode`, apiAccess)
      .then((response: any) => {
        const serverBillingCodes = response.data.map((billingCode: any) => (
          <ComboBoxItem
            key={billingCode.billingcodeid}
            text={billingCode.name}
          />
        ));
        setBillingCodes(serverBillingCodes);
      });
    axios.get(`${API_URL}/crud/product`, apiAccess).then((response: any) => {
      setAllProducts(response.data);
    });
  }, [cookie]);

  /**
   * Handle product update
   */
  useEffect(() => {}, [product]);

  /**
   * Handles inputs changes
   * @param event Input event
   * @param field Field to be affected
   */
  const handleInputChange = (event: any, field: string) => {
    setProduct({ ...product, [field]: event.target.value });
  };

  /**
   * Handles comboboxes changes
   * @param event Combobox event
   * @param field Field to be affected
   */
  const handleComboBoxChange = (event: any, field: string) => {
    switch (field) {
      case "productTypeId":
        for (let i = 0; i < productTypes.length; i++) {
          if (productTypes[i].props.text === event.target.value.toString()) {
            setProduct({ ...product, [field]: parseInt(productTypes[i].key) });
            break;
          }
        }
        break;
      case "billingCodeId":
        for (let i = 0; i < billingCodes.length; i++) {
          if (billingCodes[i].props.text === event.target.value.toString()) {
            setProduct({ ...product, [field]: parseInt(billingCodes[i].key) });
            break;
          }
        }
        break;
    }
  };

  /**
   * Sets the current products type
   * @returns Current products type
   */
  const getSelectedProductType = (): string => {
    if (product.productTypeId === 0) {
      return "";
    }
    for (let i = 0; i < productTypes.length; i++) {
      if (productTypes[i].key === product.productTypeId.toString()) {
        return productTypes[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current products billing code
   * @returns Current products billing code
   */
  const getSelectedBillingCode = (): string => {
    if (product.billingCodeId === 0) {
      return "";
    }
    for (let i = 0; i < billingCodes.length; i++) {
      if (billingCodes[i].key === product.billingCodeId.toString()) {
        return billingCodes[i].props.text;
      }
    }
    return "";
  };

  /**
   * Searches product by his code
   * @param code Products code
   * @returns Products data by code
   */
  const findProductById = async (productId: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/find-product-by-id`,
        {
          productId: productId,
        },
        apiAccess
      );
      return [response.data.rows, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Maps products data
   * @param data Products uncleaned data
   * @returns Products cleaned data
   */
  const getFoundedProductData = (data: any): ProductDTO => {
    return {
      productId: data[0].productid,
      code: data[0].code,
      quickOverview: data[0].quickoverview,
      productTypeId: data[0].producttypeid,
      unitPrice: data[0].unitprice,
      discount: data[0].discount,
      billingCodeId: data[0].billingcodeid,
      detailedDescription: data[0].detaileddescription,
    };
  };

  /**
   * Searches product by code
   */
  const findInternalProduct = async () => {
    const [findProductByCodeData] = await findProductById(productSearch);
    if (!findProductByCodeData) {
      displayMessage(
        "Error al buscar producto",
        "El producto no se encuentra en el sistema."
      );
      setProduct(productInitialState);
      return;
    }
    if (findProductByCodeData.length > 0) {
      setProduct(getFoundedProductData(findProductByCodeData));
      return;
    }
    setProduct(productInitialState);
  };

  /**
   * Saves the current product
   * @param product Product to be saved
   * @returns Products saved data
   */
  const saveInternalProduct = async (product: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/product/post`,
        product,
        apiAccess
      );
      return [
        {
          products: response.data.data,
          duplicate: response.data.duplicate || false,
        },
        null,
      ];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Updates the current product
   * @param product Product to be updated
   * @returns Products updated data
   */
  const updateInternalProduct = async (product: any) => {
    try {
      const response = await axios.put(
        `${API_URL}/crud/product`,
        product,
        apiAccess
      );
      return [response.data.data[0], null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Cleans products save data
   * @param data Products data
   * @returns Products clean data
   */
  const productSaveData = (data: any) => {
    const attributes = Object.keys(data);
    let finalData: any = {};
    for (let i = 0; i < attributes.length; i++) {
      finalData[attributes[i]] = data[attributes[i]];
    }
    delete finalData.search;
    delete finalData.productId;
    return finalData;
  };

  /**
   * Cleans products update data
   * @param data Products data
   * @returns Products clean data
   */
  const productUpdateData = (data: any) => {
    const attributes = Object.keys(data);
    let finalData: any = {};
    for (let i = 0; i < attributes.length; i++) {
      finalData[attributes[i]] = data[attributes[i]];
    }
    delete finalData.search;
    delete finalData.productId;
    return {
      new: finalData,
      filter: {
        productId: data.productId,
      },
    };
  };

  /**
   * Verifies product data integrity
   * @returns True if the products data is valid
   */
  const verifyProductIntegrity = async () => {
    if (product.quickOverview === "") {
      displayMessage("Error", "Ingresa la descripción rápida");
      return false;
    }
    if (product.productTypeId === 0) {
      displayMessage("Error", "Ingresa el tipo de producto");
      return false;
    }
    if (product.unitPrice === 0) {
      displayMessage("Error", "Ingresa el precio unitario");
      return false;
    }
    if (product.billingCodeId === 0) {
      displayMessage("Error", "Ingresa el código de facturación");
      return false;
    }
    if (product.detailedDescription === "") {
      displayMessage("Error", "Ingresa la descripción detallada");
      return false;
    }
    return true;
  };

  /**
   * Save product orchestrator
   */
  const saveProduct = async () => {
    const currentProductValue = product;
    if (currentProductValue.productId === 0) {
      if (!(await verifyProductIntegrity())) return;
      const [saveData, saveProductError]: any = await saveInternalProduct(
        productSaveData(currentProductValue)
      );

      if (saveProductError) {
        displayMessage(
          "Error al crear producto",
          "Verifica los datos e intenta nuevamente."
        );
        return;
      }

      if (saveData.duplicate) {
        setDuplicate(saveData.products);
        setMustShow(true);
        return;
      }

      const saveProductData = saveData.products[0];
      setProduct({ ...product, productId: saveProductData.productid });
      setProductSearch(saveProductData.productid);
      displayMessage(
        "Producto creado",
        `Producto #${saveProductData.productid} creado.`
      );
    } else {
      if (!(await verifyProductIntegrity())) return;
      const [updateProductData, updateProductError] =
        await updateInternalProduct(productUpdateData(currentProductValue));
      if (updateProductError) {
        displayMessage(
          "Error al crear producto",
          "Verifica los datos e intenta nuevamente."
        );
        return;
      }
      displayMessage(
        "Producto actualizado",
        `Producto #${updateProductData.productid} actualizado.`
      );
    }
  };

  /**
   * Displays a dialog message
   * @param title Message title
   * @param content Message content
   */
  const displayMessage = (title: string, content: string) => {
    setMessageTitle(title);
    setMessageContent(content);
    showMessage.current?.show();
  };

  /**
   * Handles product combobox
   * @param code Products code
   */
  const handleProductComboBox = (code: string) => {
    const productCode = code.split(" ")[0];
    for (let i = 0; i < allProducts.length; i++) {
      const product = allProducts[i];
      if (product.productid.toString() === productCode) {
        setProductSearch(productCode);
        return;
      }
    }
  };

  const setDuplicatedProduct = (product: any[]) => {
    setProduct(getFoundedProductData(product));
    setMustShow(false);
  };

  if (duplicate.length > 0 && mustShow) {
    return (
      <DuplicateProducts
        products={duplicate}
        setMustShow={setMustShow}
        setProduct={setDuplicatedProduct}
      />
    );
  }

  return (
    <div className={styles.product}>
      <Form
        titleText="Búsqueda"
        columnsXL={2}
        columnsL={2}
        columnsM={1}
        columnsS={1}
      >
        <FormItem label="Código">
          <Autocomplete
            className={styles.input}
            size="small"
            options={allProducts}
            getOptionLabel={(option) =>
              `${option.productid} ${option.quickoverview}`
            }
            isOptionEqualToValue={(option, value) =>
              option.productid === value.productid
            }
            filterSelectedOptions
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.productid}>
                {option.productid} {option.quickoverview}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e: any, newValue: any) => {
              if (newValue) {
                setProductSearch(newValue.productid);
              }
            }}
          />
        </FormItem>
        <FormItem label="">
          <Button
            icon="search"
            onClick={() => findInternalProduct()}
            className={styles.button}
          >
            Consultar
          </Button>
        </FormItem>
        {
          <FormItem label="">
            {product.productId !== 0 && (
              <Button
                icon="add"
                onClick={() => {
                  setProductSearch("");
                  setProduct(productInitialState);
                }}
                className={styles.button}
                design="Positive"
              >
                Nuevo
              </Button>
            )}
          </FormItem>
        }
      </Form>
      <br />
      <Form
        titleText="Información del Producto"
        columnsXL={1}
        columnsL={1}
        columnsM={1}
        columnsS={1}
      >
        <FormItem label="Descripción Rápida">
          <Input
            icon={<Icon name="hint" />}
            onInput={(e) => handleInputChange(e, "quickOverview")}
            value={product.quickOverview}
            className={styles.input}
            style={{
              border: product.quickOverview === "" ? "2px solid red" : "",
            }}
          />
        </FormItem>
        <FormItem label="Tipo">
          <ComboBox
            icon={<Icon name="product" />}
            onChange={(e) => handleComboBoxChange(e, "productTypeId")}
            value={getSelectedProductType()}
            className={styles.input}
            style={{
              border: product.productTypeId === 0 ? "2px solid red" : "",
            }}
          >
            {productTypes}
          </ComboBox>
        </FormItem>
        <FormItem label="Precio Unitario">
          <Input
            type="Number"
            icon={<Icon name="lead" />}
            onInput={(e) => handleInputChange(e, "unitPrice")}
            value={product.unitPrice.toString()}
            className={styles.input}
            style={{
              border: product.unitPrice === 0 ? "2px solid red" : "",
            }}
          />
        </FormItem>
        <FormItem label="Descuento">
          <Input
            type="Number"
            icon={<Icon name="waiver" />}
            onInput={(e) => handleInputChange(e, "discount")}
            value={product.discount.toString()}
            className={styles.input}
          />
        </FormItem>
        <FormItem label="Código de Facturación">
          <ComboBox
            icon={<Icon name="factory" />}
            onChange={(e) => handleComboBoxChange(e, "billingCodeId")}
            value={getSelectedBillingCode()}
            className={styles.input}
            style={{
              border: product.billingCodeId === 0 ? "2px solid red" : "",
            }}
          >
            {billingCodes}
          </ComboBox>
        </FormItem>
        <FormItem label="Descripción Detallada">
          <TextArea
            onInput={(e) => handleInputChange(e, "detailedDescription")}
            value={product.detailedDescription}
            className={styles.input}
            style={{
              border: product.detailedDescription === "" ? "2px solid red" : "",
            }}
          />
        </FormItem>
        <FormItem label="">
          <Button
            icon="product"
            onClick={() => saveProduct()}
            className={styles.button}
            design="Emphasized"
          >
            {product.productId === 0 ? "Crear Producto" : "Modificar Producto"}
          </Button>
        </FormItem>
      </Form>
      <Dialog
        ref={showMessage}
        footer={
          <div style={{ padding: "5px" }}>
            <Button
              onClick={() => showMessage.current?.close()}
              design="Emphasized"
            >
              Cerrar
            </Button>
          </div>
        }
        headerText={messageTitle}
      >
        <div style={{ padding: "5px" }}>{messageContent}</div>
      </Dialog>
    </div>
  );
}

export default Product;
